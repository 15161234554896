import {
  TButtonEvent,
  TCheckboxEvent,
  TFieldEvent,
  TRadioEvent,
  TTextBoxEvent,
} from './form-tracking.types';

export function getFieldTrackingData(
  element: HTMLFormElement,
  valueMap?: Record<string, string>
): TFieldEvent | undefined {
  const { type, textContent, value } = element;
  const testId = element.getAttribute?.('data-testid');
  const hiddenInput = element.nextSibling as HTMLFormElement | null;
  const fieldValue = valueMap?.[value] || value;

  if (type === 'text' || type === 'number') {
    return getTextboxData({ text: fieldValue });
  }

  if (type === 'button' && testId?.includes('toggle-')) {
    return getButtonData({ text: textContent, testId });
  }

  if (type === 'button' && testId?.includes('radio-')) {
    const question = getRadioLabel(element);

    return getRadioData({ question, answer: fieldValue });
  }

  if (element.getAttribute?.('role') === 'checkbox') {
    const checked = fieldValue === 'on';
    return getCheckboxData({
      checked,
      name: hiddenInput?.name,
    });
  }

  return undefined;
}

function getTextboxData({ text }: { text: string }): TTextBoxEvent {
  return {
    event: 'textbox',
    children: text,
  };
}

function getButtonData({
  text,
  testId,
}: {
  text: string | null;
  testId: string;
}): TButtonEvent {
  return {
    event: 'button',
    children: text || '',
    dataTestId: testId || '',
  };
}

function getRadioData({
  question,
  answer,
}: {
  question?: string | null;
  answer: string;
}): TRadioEvent {
  return {
    event: 'radio',
    question: question || '',
    answer,
  };
}

function getCheckboxData({
  checked,
  name = '',
}: {
  checked: boolean;
  name?: string;
}): TCheckboxEvent {
  return {
    event: 'checkbox',
    checked,
    name,
  };
}

function getRadioLabel(input: HTMLFormElement): string | null | undefined {
  return input.closest('[role="radiogroup"]')?.getAttribute('data-group-label');
}
