import { TEventData, TCustomWindow } from './google-analytics-tracker.types';

export const dataLayerPush = (event: string, data: TEventData): void => {
  const customWindow = window as TCustomWindow;

  if (typeof window !== 'undefined' && event && data) {
    try {
      customWindow.dataLayer = customWindow.dataLayer || [];
      customWindow.dataLayer.push({
        event,
        ...data,
      });
    } catch (error) {
      console.error('Error occurred during dataLayer push:', error);
    }
  }
};
