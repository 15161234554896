import { ECallbackAgentType } from '../../mortgages.types';
import { EFieldName } from '../../create-callback-request/create-callback-request.types';
import { addHoursMinutesToDate } from '../helpers';

export function transformRemortgageData<
  T extends { [key in EFieldName]?: any },
>(values: T) {
  return {
    [EFieldName.FIRST_NAME]: values[EFieldName.FIRST_NAME],
    [EFieldName.LAST_NAME]: values[EFieldName.LAST_NAME],
    [EFieldName.PHONE_NUMBER]: values[EFieldName.PHONE_NUMBER],
    [EFieldName.EMAIL]: values[EFieldName.EMAIL],
    [EFieldName.CALL_BACK_WHEN]: addHoursMinutesToDate(
      values[EFieldName.CALL_ISO_DATE],
      values[EFieldName.CALL_TIME]
    ),
    [EFieldName.ACCEPT_TCS]: values[EFieldName.ACCEPT_TCS],
    [EFieldName.REMORTGAGE_INTENTION]: {
      [EFieldName.WHEN_DO_YOU_NEED_REMORTGAGE]:
        values[EFieldName.WHEN_DO_YOU_NEED_REMORTGAGE],
    },
    [EFieldName.CALL_BACK_AGENT_TYPE]: ECallbackAgentType.REMORTGAGE_ADVISOR,
    [EFieldName.CALL_DATE]: values[EFieldName.CALL_DATE],
  };
}
