import { TAppointment } from '@pbx/shared/api-client/services/mortgages';
import { addTimezoneOffsetToTime } from '@pbx/shared/utilities/add-timezoneoffset-to-time';

import { CALL_TIME_OPTIONS } from '../lead-capture-form.constants';

export function getAppointmentsByDate(appointments: TAppointment[]) {
  return (appointments || []).reduce<Record<string, string[]>>(
    (appointments, current) => {
      const { date, times } = current;
      const [dateNoTime] = date.split('T');

      return {
        ...appointments,
        [dateNoTime]: (times || []).map((time) => time.from),
      };
    },
    {}
  );
}

export function getCallTimeOptions(
  appointmentsByDate: Record<string, string[]>,
  selectedDate: string,
  timezoneOffsetMinutes = 0
) {
  const [selectedDateNoTime] = selectedDate.split('T');
  const times = appointmentsByDate[selectedDateNoTime] || [];

  return CALL_TIME_OPTIONS.map((option) => {
    // add timezone offset if user is from different timezone
    const adjustedTime = addTimezoneOffsetToTime(
      option.value,
      timezoneOffsetMinutes
    );

    return {
      ...option,
      disabled: !times.includes(adjustedTime),
    };
  });
}

export function getLastAvailableAppointment(
  callbackAppointments?: TAppointment[]
): Date | undefined {
  const lastAvailableDate = (callbackAppointments || []).at(-1)?.date;

  if (!lastAvailableDate) return;

  return new Date(lastAvailableDate);
}
