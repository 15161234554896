'use client';

import * as React from 'react';
import { DayFlag, DayPicker, UI } from 'react-day-picker';

import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Icon } from '@pbx/shared/ui/common/icon';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

export function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn(
        'bg-background-primary border-border-contrast rounded-medium relative flex w-fit border p-3',
        className
      )}
      timeZone="UTC"
      classNames={{
        months: 'flex flex-col sm:flex-row sm:space-y-0',
        month: 'space-y-4',
        [UI.MonthCaption]: 'flex justify-center relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        [UI.MonthGrid]: 'w-full border-collapse space-y-1',
        [UI.Weekdays]: 'flex',
        [UI.Weekday]:
          'text-foreground-secondary rounded-md w-9 font-normal text-[0.8rem]',
        [UI.Week]: 'flex w-full mt-2',
        [UI.Day]:
          'h-9 w-9 hover:bg-background-disabled flex items-center justify-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        range_end: 'day-range-end',
        selected:
          'text-foreground-highlight hover:text-foreground-highlight focus:text-foreground-highlight font-bold',
        [DayFlag.today]: 'font-bold',
        outside: 'day-outside',
        disabled:
          'text-foreground-disabled opacity-50 hover:bg-background-primary',
        range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        hidden: 'invisible',
        ...classNames,
      }}
      components={{
        PreviousMonthButton: (props) => (
          <button
            {...props}
            className="absolute left-4 top-4 z-10 flex h-8 w-8 cursor-pointer items-center justify-center"
          >
            <Icon
              className="text-foreground-action h-4 w-4"
              name="arrow-back"
            />
          </button>
        ),
        NextMonthButton: ({ ...props }) => (
          <button
            {...props}
            className="absolute right-4 top-4 z-10 flex h-8 w-8 cursor-pointer items-center justify-center"
          >
            <Icon
              className="text-foreground-action top-4 h-4 w-4"
              name="arrow-forward"
            />
          </button>
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';
