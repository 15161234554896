import { z } from 'zod';

import {
  amountValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  phoneNumberValidation,
  yesNoValidation,
} from '@pbx/shared/utilities/validation/zod';

import {
  EFieldName,
  EWhatDoYouNeed,
  EWhenDoYouNeedRemortgage,
  EWhereYouAt,
} from './create-callback-request.types';

const baseSchema = z.object({
  [EFieldName.FIRST_NAME]: firstNameValidation,
  [EFieldName.LAST_NAME]: lastNameValidation,
  [EFieldName.PHONE_NUMBER]: phoneNumberValidation,
  [EFieldName.EMAIL]: emailValidation,
  [EFieldName.WHAT_DO_YOU_NEED]: z
    .nativeEnum(EWhatDoYouNeed, {
      message: 'Please select an option!',
    })
    .optional(),
  [EFieldName.ACCEPT_TCS]: z.literal(true, {
    errorMap: () => ({
      message: 'You must accept the privacy policy and terms & conditions',
    }),
  }),
  [EFieldName.CALL_DATE]: z.string({
    message: 'Please select an option!',
  }),
  [EFieldName.CALL_ISO_DATE]: z.string().optional(),
  [EFieldName.CALL_TIME]: z.string({
    message: 'Please select an option!',
  }),
});

export const createCallbackRequestSchema = z.union([
  z
    .object({
      [EFieldName.WHAT_DO_YOU_NEED]: z.literal(EWhatDoYouNeed.PURCHASE),
      [EFieldName.WHERE_ARE_YOU_AT]: z
        .nativeEnum(EWhereYouAt, {
          message: 'Please select an option!',
        })
        .optional(),
      [EFieldName.ANNUAL_INCOME]: amountValidation('Annual income'),
      [EFieldName.PROPERTY_VALUE]: amountValidation('Property value'),
      [EFieldName.DEPOSIT]: amountValidation('Deposit'),
      [EFieldName.UK_CITIZEN]: yesNoValidation,
      [EFieldName.ANY_CCJS]: yesNoValidation,
      [EFieldName.EXTRA_SUPPORT]: yesNoValidation,
    })
    .merge(baseSchema),
  z
    .object({
      [EFieldName.WHAT_DO_YOU_NEED]: z.literal(EWhatDoYouNeed.RE_MORTGAGE),
      [EFieldName.WHEN_DO_YOU_NEED_REMORTGAGE]: z
        .nativeEnum(EWhenDoYouNeedRemortgage, {
          message: 'Please select an option!',
        })
        .optional(),
    })
    .merge(baseSchema),
]);
