import { format } from 'date-fns/format';

import {
  ECallbackAvailability,
  EWhatDoYouNeed,
  EWhenDoYouNeedRemortgage,
  EWhereYouAt,
  EYesNo,
  TCallbackAppointments,
} from '@pbx/shared/api-client/services/mortgages';
import { getUpcomingDays } from '@pbx/shared/utilities/get-upcoming-days';

export const TIME_SLOTS = [
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
] as const;

const TIMES_MOCK = [
  { from: '07:00:00' },
  { from: '08:00:00' },
  { from: '09:00:00' },
  { from: '10:00:00' },
  { from: '11:00:00' },
  { from: '12:00:00' },
  { from: '13:00:00' },
  { from: '14:00:00' },
  { from: '15:00:00' },
  { from: '16:00:00' },
  { from: '17:00:00' },
  { from: '18:00:00' },
  { from: '19:00:00' },
  { from: '20:00:00' },
];

export const CALLBACK_APPOINTMENTS_MOCK: TCallbackAppointments = {
  [ECallbackAvailability.MORTGAGE_ADVISOR_AVAILABILITY]: [
    {
      date: '2024-10-15T00:00:00Z',
      times: TIMES_MOCK,
    },
  ],
  [ECallbackAvailability.REMORTGAGE_ADVISOR_AVAILABILITY]: [
    {
      date: '2024-10-15T00:00:00Z',
      times: TIMES_MOCK,
    },
  ],
  [ECallbackAvailability.HELPLINE_AVAILABILITY]: [
    {
      date: '2024-10-15T00:00:00Z',
      times: TIMES_MOCK,
    },
  ],
};

const [todayDate, tomorrowDate, afterTomorrowDate] = getUpcomingDays(3);

export const CALLBACK_APPOINTMENTS_MOCK_STORY: TCallbackAppointments = {
  [ECallbackAvailability.MORTGAGE_ADVISOR_AVAILABILITY]: [
    {
      date: todayDate.toISOString(),
      times: TIMES_MOCK,
    },
    {
      date: tomorrowDate.toISOString(),
      times: TIMES_MOCK,
    },
    {
      date: afterTomorrowDate.toISOString(),
      times: TIMES_MOCK,
    },
  ],
  [ECallbackAvailability.REMORTGAGE_ADVISOR_AVAILABILITY]: [
    {
      date: todayDate.toISOString(),
      times: TIMES_MOCK,
    },
    {
      date: tomorrowDate.toISOString(),
      times: TIMES_MOCK,
    },
    {
      date: afterTomorrowDate.toISOString(),
      times: TIMES_MOCK,
    },
  ],
  [ECallbackAvailability.HELPLINE_AVAILABILITY]: [
    {
      date: todayDate.toISOString(),
      times: TIMES_MOCK,
    },
    {
      date: tomorrowDate.toISOString(),
      times: TIMES_MOCK,
    },
    {
      date: afterTomorrowDate.toISOString(),
      times: TIMES_MOCK,
    },
  ],
};

export const ANNUAL_INCOME_LABEL = 'Annual income of all applicants';
export const PROPERTY_VALUE_LABEL = 'Property value';
export const DEPOSIT_LABEL = 'Deposit';
export const UK_CITIZEN_TEST_ID = 'ukcitizen-toggle';
export const ANY_CCJS_TEST_ID = 'any-ccjs-toggle';
export const EXTRA_SUPPORT_TEST_ID = 'extra-support';

export const WHAT_DO_YOU_NEED_OPTIONS = [
  { value: EWhatDoYouNeed.PURCHASE, label: 'Purchase' },
  { value: EWhatDoYouNeed.RE_MORTGAGE, label: 'Re-mortgage' },
];

export const RADIO_FIELD_LABELS: Record<
  EWhereYouAt | EWhenDoYouNeedRemortgage,
  string
> = {
  [EWhereYouAt.JUST_LOOKING]: 'Just starting out',
  [EWhereYouAt.CASUALLY_LOOKING]: 'Casually looking at properties',
  [EWhereYouAt.ACTIVELY_VIEWING]: 'Actively viewing properties',
  [EWhereYouAt.READY_TO_OFFER]: 'Getting ready to make an offer',
  [EWhereYouAt.MADE_ON_OFFER]: 'Made an offer',
  [EWhereYouAt.OFFER_ACCEPTED]: 'Had an offer accepted!',
  [EWhenDoYouNeedRemortgage.LESS_THAN_3_MONTHS]: 'Less than 3 months',
  [EWhenDoYouNeedRemortgage.THREE_TO_SIX_MONTHS]: '3–6 months',
  [EWhenDoYouNeedRemortgage.SIX_MONTHS_PLUS]: '6 months+',
};

export const WHERE_ARE_YOU_AT_OPTIONS = [
  {
    value: EWhereYouAt.JUST_LOOKING,
    label: RADIO_FIELD_LABELS[EWhereYouAt.JUST_LOOKING],
  },
  {
    value: EWhereYouAt.CASUALLY_LOOKING,
    label: RADIO_FIELD_LABELS[EWhereYouAt.CASUALLY_LOOKING],
  },
  {
    value: EWhereYouAt.ACTIVELY_VIEWING,
    label: RADIO_FIELD_LABELS[EWhereYouAt.ACTIVELY_VIEWING],
  },
  {
    value: EWhereYouAt.READY_TO_OFFER,
    label: RADIO_FIELD_LABELS[EWhereYouAt.READY_TO_OFFER],
  },
  {
    value: EWhereYouAt.MADE_ON_OFFER,
    label: RADIO_FIELD_LABELS[EWhereYouAt.MADE_ON_OFFER],
  },
  {
    value: EWhereYouAt.OFFER_ACCEPTED,
    label: RADIO_FIELD_LABELS[EWhereYouAt.OFFER_ACCEPTED],
  },
];

export const YES_NO_OPTIONS = [
  { value: EYesNo.YES, label: 'Yes' },
  { value: EYesNo.NO, label: 'No' },
];

export const WHEN_DO_YOU_NEED_REMORTGAGE_OPTIONS = [
  {
    value: EWhenDoYouNeedRemortgage.LESS_THAN_3_MONTHS,
    label: RADIO_FIELD_LABELS[EWhenDoYouNeedRemortgage.LESS_THAN_3_MONTHS],
  },
  {
    value: EWhenDoYouNeedRemortgage.THREE_TO_SIX_MONTHS,
    label: RADIO_FIELD_LABELS[EWhenDoYouNeedRemortgage.THREE_TO_SIX_MONTHS],
  },
  {
    value: EWhenDoYouNeedRemortgage.SIX_MONTHS_PLUS,
    label: RADIO_FIELD_LABELS[EWhenDoYouNeedRemortgage.SIX_MONTHS_PLUS],
  },
];

export const CALL_TIME_OPTIONS = TIME_SLOTS.map((time) => ({
  value: time,
  label: time,
  disabled: false,
}));

export const SUBMIT_ERROR_MESSAGE = 'Something went wrong, please try again';

export const ACCEPT_TCS_LABEL =
  'I accept the Purplebricks Mortgages Limited privacy policy and terms and conditions.';

export const BEST_CALL_DAY_TEXT: Record<string, string> = {
  '0': 'today',
  '1': 'tomorrow',
  '2': format(getUpcomingDays(3)[2], 'E, do'),
  '3': 'More Dates',
};
