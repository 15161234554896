import { useFormContext } from 'react-hook-form';
import React from 'react';

import { FormField } from '@pbx/shared/ui/common/form/form';
import { RadioGroup } from '@pbx/shared/ui/common/form/radio-group';
import { EFieldName } from '@pbx/shared/api-client/services/mortgages';

import { TUseFormReturn } from '../lead-capture-form.types';
import { WHEN_DO_YOU_NEED_REMORTGAGE_OPTIONS } from '../lead-capture-form.constants';

export function ReMortgageJourney() {
  const { control } = useFormContext<TUseFormReturn>();

  return (
    <FormField control={control} name={EFieldName.WHEN_DO_YOU_NEED_REMORTGAGE}>
      <RadioGroup
        label="When do you need to remortgage?"
        options={WHEN_DO_YOU_NEED_REMORTGAGE_OPTIONS}
        data-group-label="When do you need to remortgage?"
      />
    </FormField>
  );
}
