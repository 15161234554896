interface GetResultsResult {
  firstTimeBuyer: number;
  movingHome: number;
  additionalHome: number;
}

export function getResults({
  selectedLocation,
  propertyValue,
}: {
  selectedLocation: 'scotland' | 'wales' | 'england';
  propertyValue: number;
}): GetResultsResult {
  if (isNaN(propertyValue)) {
    return {
      firstTimeBuyer: 0,
      movingHome: 0,
      additionalHome: 0,
    };
  }

  let taxFreeAmount = 0;
  let taxFreeAmountFirstTime = 0;
  let taxFreeAmountFirstTimeMaximum = 0;
  let taxFreeAmountThresholdSecondHomeOne = 0;
  let taxFreeAmountThresholdSecondHomeTwo = 0;
  let taxFreeAmountThresholdOne = 0;
  let taxFreeAmountThresholdTwo = 0;
  let taxFreeAmountThresholdThree = 0;

  switch (selectedLocation) {
    case 'scotland':
      taxFreeAmount = 145000;
      taxFreeAmountFirstTime = 175000;
      taxFreeAmountThresholdOne = 250000;
      taxFreeAmountThresholdTwo = 325000;
      taxFreeAmountThresholdThree = 750000;

      // more than £750k
      if (propertyValue > taxFreeAmountThresholdThree) {
        return {
          firstTimeBuyer:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.02 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.05 +
            (taxFreeAmountThresholdThree - taxFreeAmountThresholdTwo) * 0.1 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.12,
          movingHome:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.02 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.05 +
            (taxFreeAmountThresholdThree - taxFreeAmountThresholdTwo) * 0.1 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.12,
          additionalHome:
            taxFreeAmount * 0.06 +
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.08 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.11 +
            (taxFreeAmountThresholdThree - taxFreeAmountThresholdTwo) * 0.16 +
            (propertyValue - taxFreeAmountThresholdThree) * 0.18,
        };
      }
      // more than £325k
      if (propertyValue > taxFreeAmountThresholdTwo) {
        return {
          firstTimeBuyer:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.02 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.05 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.1,
          movingHome:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.02 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.05 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.1,
          additionalHome:
            taxFreeAmount * 0.06 +
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.08 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.11 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.16,
        };
      }
      // more than £250k
      if (propertyValue > taxFreeAmountThresholdOne) {
        return {
          firstTimeBuyer:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.02 +
            (propertyValue - taxFreeAmountThresholdOne) * 0.05,
          movingHome:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.02 +
            (propertyValue - taxFreeAmountThresholdOne) * 0.05,
          additionalHome:
            taxFreeAmount * 0.06 +
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.08 +
            (propertyValue - taxFreeAmountThresholdOne) * 0.11,
        };
      }
      // more than £175k
      if (propertyValue > taxFreeAmountFirstTime) {
        return {
          firstTimeBuyer: (propertyValue - taxFreeAmountFirstTime) * 0.02, // first £175k tax free,
          movingHome: (propertyValue - taxFreeAmount) * 0.02,
          additionalHome:
            taxFreeAmount * 0.06 + (propertyValue - taxFreeAmount) * 0.08,
        };
      }
      // more than £145k
      if (propertyValue > taxFreeAmount) {
        return {
          firstTimeBuyer: 0,
          movingHome: (propertyValue - taxFreeAmount) * 0.02,
          additionalHome:
            taxFreeAmount * 0.06 + (propertyValue - taxFreeAmount) * 0.08,
        };
      }
      // less than £145k
      if (propertyValue <= taxFreeAmount) {
        return {
          firstTimeBuyer: 0,
          movingHome: 0,
          additionalHome: propertyValue * 0.06,
        };
      }

      break;
    case 'wales':
      taxFreeAmount = 225000;
      taxFreeAmountFirstTime = 225000;
      taxFreeAmountThresholdSecondHomeOne = 180000;
      taxFreeAmountThresholdSecondHomeTwo = 250000;
      taxFreeAmountThresholdOne = 400000;
      taxFreeAmountThresholdTwo = 750000;
      taxFreeAmountThresholdThree = 1500000;

      // more than £1500k
      if (propertyValue > taxFreeAmountThresholdThree) {
        return {
          firstTimeBuyer:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.06 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.075 +
            (taxFreeAmountThresholdThree - taxFreeAmountThresholdTwo) * 0.1 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.12,
          movingHome:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.06 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.075 +
            (taxFreeAmountThresholdThree - taxFreeAmountThresholdTwo) * 0.1 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.12,
          additionalHome:
            taxFreeAmountThresholdSecondHomeOne * 0.04 +
            (taxFreeAmountThresholdSecondHomeTwo -
              taxFreeAmountThresholdSecondHomeOne) *
              0.075 +
            (taxFreeAmountThresholdOne - taxFreeAmountThresholdSecondHomeTwo) *
              0.09 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.115 +
            (taxFreeAmountThresholdThree - taxFreeAmountThresholdTwo) * 0.14 +
            (propertyValue - taxFreeAmountThresholdThree) * 0.16,
        };
      }

      // more than £750k
      if (propertyValue > taxFreeAmountThresholdTwo) {
        return {
          firstTimeBuyer:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.06 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.075 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.1,
          movingHome:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.06 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.075 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.1,
          additionalHome:
            taxFreeAmountThresholdSecondHomeOne * 0.04 +
            (taxFreeAmountThresholdSecondHomeTwo -
              taxFreeAmountThresholdSecondHomeOne) *
              0.075 +
            (taxFreeAmountThresholdOne - taxFreeAmountThresholdSecondHomeTwo) *
              0.09 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.115 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.14,
        };
      }

      // more than £400k
      if (propertyValue > taxFreeAmountThresholdOne) {
        return {
          firstTimeBuyer:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.06 +
            (propertyValue - taxFreeAmountThresholdOne) * 0.075,
          movingHome:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.06 +
            (propertyValue - taxFreeAmountThresholdOne) * 0.075,
          additionalHome:
            taxFreeAmountThresholdSecondHomeOne * 0.04 +
            (taxFreeAmountThresholdSecondHomeTwo -
              taxFreeAmountThresholdSecondHomeOne) *
              0.075 +
            (taxFreeAmountThresholdOne - taxFreeAmountThresholdSecondHomeTwo) *
              0.09 +
            (propertyValue - taxFreeAmountThresholdOne) * 0.115,
        };
      }

      // more than £250k
      if (propertyValue > taxFreeAmountThresholdSecondHomeTwo) {
        return {
          firstTimeBuyer: (propertyValue - taxFreeAmount) * 0.06,
          movingHome: (propertyValue - taxFreeAmount) * 0.06,
          additionalHome:
            taxFreeAmountThresholdSecondHomeOne * 0.04 +
            (taxFreeAmountThresholdSecondHomeTwo -
              taxFreeAmountThresholdSecondHomeOne) *
              0.075 +
            (propertyValue - taxFreeAmountThresholdSecondHomeTwo) * 0.09,
        };
      }

      // more than £225k
      if (propertyValue > taxFreeAmount) {
        return {
          firstTimeBuyer: (propertyValue - taxFreeAmount) * 0.06,
          movingHome: (propertyValue - taxFreeAmount) * 0.06,
          additionalHome:
            taxFreeAmountThresholdSecondHomeOne * 0.04 +
            (propertyValue - taxFreeAmountThresholdSecondHomeOne) * 0.075,
        };
      }

      // between £180k and £225k
      if (
        propertyValue > taxFreeAmountThresholdSecondHomeOne &&
        propertyValue <= taxFreeAmount
      ) {
        return {
          firstTimeBuyer: 0,
          movingHome: 0,
          additionalHome:
            taxFreeAmountThresholdSecondHomeOne * 0.04 +
            (propertyValue - taxFreeAmountThresholdSecondHomeOne) * 0.075,
        };
      }

      // less than £180k
      if (propertyValue <= taxFreeAmountThresholdSecondHomeOne) {
        return {
          firstTimeBuyer: 0,
          movingHome: 0,
          additionalHome: propertyValue * 0.04,
        };
      }

      break;
    case 'england':
    default:
      taxFreeAmount = 250000;
      taxFreeAmountFirstTime = 425000;
      taxFreeAmountFirstTimeMaximum = 625000;
      taxFreeAmountThresholdOne = 925000;
      taxFreeAmountThresholdTwo = 1500000;

      // more than £1500k
      if (propertyValue > taxFreeAmountThresholdTwo) {
        return {
          firstTimeBuyer:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.05 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.1 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.12,
          movingHome:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.05 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.1 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.12,
          additionalHome:
            taxFreeAmount * 0.03 +
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.08 +
            (taxFreeAmountThresholdTwo - taxFreeAmountThresholdOne) * 0.13 +
            (propertyValue - taxFreeAmountThresholdTwo) * 0.15,
        };
      }

      // more than £925k
      if (propertyValue > taxFreeAmountThresholdOne) {
        return {
          firstTimeBuyer:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.05 +
            (propertyValue - taxFreeAmountThresholdOne) * 0.1,
          movingHome:
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.05 +
            (propertyValue - taxFreeAmountThresholdOne) * 0.1,
          additionalHome:
            taxFreeAmount * 0.03 +
            (taxFreeAmountThresholdOne - taxFreeAmount) * 0.08 +
            (propertyValue - taxFreeAmountThresholdOne) * 0.13,
        };
      }

      // more than £625k
      if (propertyValue > taxFreeAmountFirstTimeMaximum) {
        return {
          firstTimeBuyer: (propertyValue - taxFreeAmount) * 0.05, // house price over Â£625k, so only first Â£250k tax free
          movingHome: (propertyValue - taxFreeAmount) * 0.05,
          additionalHome:
            taxFreeAmount * 0.03 + (propertyValue - taxFreeAmount) * 0.08,
        };
      }

      // more than £425k
      if (propertyValue > taxFreeAmountFirstTime) {
        return {
          firstTimeBuyer: (propertyValue - taxFreeAmountFirstTime) * 0.05, // first £425k tax free
          movingHome: (propertyValue - taxFreeAmount) * 0.05,
          additionalHome:
            taxFreeAmount * 0.03 + (propertyValue - taxFreeAmount) * 0.08,
        };
      }

      // more than £250k
      if (propertyValue > taxFreeAmount) {
        return {
          firstTimeBuyer: 0,
          movingHome: (propertyValue - taxFreeAmount) * 0.05,
          additionalHome:
            taxFreeAmount * 0.03 + (propertyValue - taxFreeAmount) * 0.08,
        };
      }

      if (propertyValue <= taxFreeAmount) {
        return {
          firstTimeBuyer: 0,
          movingHome: 0,
          additionalHome: propertyValue * 0.03,
        };
      }

      break;
  }
  return {
    firstTimeBuyer: 0,
    movingHome: 0,
    additionalHome: 0,
  };
}
