'use client';
import { useState } from 'react';

import { ECallbackAgentType } from '../../mortgages.types';
import { useCreateCallbackRequest } from '../../create-callback-request/create-callback-request';
import { useGetCallbackAppointments } from '../../get-callback-appointments/get-callback-appointments';

export function useCallbackRequests() {
  const [agentType, setAgentType] = useState<ECallbackAgentType>();
  const { mutate, isPending, isSuccess, isError } = useCreateCallbackRequest();
  const { data: callbackAppointments, isPending: isAppointmentsPending } =
    useGetCallbackAppointments({
      callbackAgentType: agentType,
      config: {
        enabled: !!agentType,
        retry: false,
      },
    });

  return {
    callbackAppointments,
    setAgentType,
    mutate,
    isFormSubmitting: isPending,
    isAppointmentsLoading: isAppointmentsPending,
    isSuccess,
    isError,
  };
}
