'use client';
import { Text } from '@pbx/shared/ui/common/text';
import { Icon } from '@pbx/shared/ui/common/icon';
import { formatNumber } from '@pbx/shared/utilities/format-number';
import { LinkButton } from '@pbx/shared/ui/common/link-button';
import { Link } from '@pbx/shared/ui/common/link';
import {
  useGetMarketingTrustPilotReviews,
  useGetMarketingTrustPilotScore,
} from '@pbx/shared/api-client/services/reviews';

import { TReviewsBlock } from './review-block.types';
import { ReviewsCarousel } from './reviews-carousel/reviews-carousel';
import { variants } from './review-block.variants';

export const ReviewBlock = ({
  title = 'The most reviewed estate agent ever',
  description = 'Over 100,000 reviews on Trustpilot',
  reviews,
  appearance = 'primary',
}: TReviewsBlock) => {
  const { data: scoreData } = useGetMarketingTrustPilotScore();
  const { data: recentReviews } = useGetMarketingTrustPilotReviews({
    stars: [5, 4],
    pageSize: 3,
    config: {
      enabled: !reviews,
    },
  });

  const { rating, totalCount } = scoreData || {};
  const displayReviews = reviews || recentReviews?.reviews;

  return (
    <div className={variants({ appearance })}>
      <div className="flex flex-col gap-8 md:container lg:container">
        <div className="flex flex-col gap-4 sm:px-4">
          {title && (
            <Text variant="display" size="medium">
              {title}
            </Text>
          )}
          {description && (
            <Text variant="paragraph" size="large">
              {description}
            </Text>
          )}
        </div>
        {displayReviews && <ReviewsCarousel reviews={displayReviews} />}
        <div className="flex gap-8 sm:flex-col sm:px-4 md:flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col gap-3">
            <Link
              newTab={true}
              href="https://www.trustpilot.com/review/purplebricks.com"
            >
              <img
                width={130}
                height={32}
                alt="trustpilot-logo"
                src={`${process.env.NEXT_PUBLIC_PB_IMGIX_URL}/trustpilot-logo-black.svg`}
              />
            </Link>
            {rating && totalCount && (
              <Text variant="paragraph" size="small">
                {rating} out of 5 from {formatNumber(totalCount)} reviews
              </Text>
            )}
          </div>
          <LinkButton
            label="See all reviews"
            variant="tertiary"
            newTab={false}
            icon={<Icon name="arrow-forward" width={24} height={24} />}
            iconPosition="right"
            link={{
              href: `${process.env.NEXT_PUBLIC_PB_WEB_HOST}/reviews`,
            }}
            className="w-fit"
          />
        </div>
      </div>
    </div>
  );
};
