import { dataLayerPush } from './data-layer-push';
import { TGoogleAnalyticsTracker } from './google-analytics-tracker.types';
import {
  trackInputChange,
  trackSubmit,
  trackSubmitError,
} from './form-tracking/form-tracking';

export const googleAnalyticsTracker = (): TGoogleAnalyticsTracker => ({
  eventTracking: (event, data) => dataLayerPush(event, data),
  trackSubmit: (data) => trackSubmit(data),
  trackSubmitError: (error) => trackSubmitError(error),
  trackInputChange: (element, valueMap) => trackInputChange(element, valueMap),
});
