'use client';

export function HousePricesOverTime() {
  return (
    <iframe
      id="house-prices-over-time"
      scrolling="no"
      title="House Price Index"
      src="https://drench-development.com/purple-bricks-HPI-over-time/index-v4.html"
      className="bg-background-primary aspect-[9/25] h-full w-full max-w-[650px] [@media(min-width:300px)]:aspect-[9/23.5] [@media(min-width:330px)]:aspect-[9/22] [@media(min-width:360px)]:aspect-[9/21] [@media(min-width:390px)]:aspect-[9/19] [@media(min-width:425px)]:aspect-[9/18] [@media(min-width:485px)]:aspect-[9/14.5] [@media(min-width:650px)]:aspect-[9/13.5]"
    ></iframe>
  );
}
