import { parseISO } from 'date-fns/parseISO';
import { formatDistance } from 'date-fns/formatDistance';

import { Text } from '@pbx/shared/ui/common/text';
import { TrustPilotStars } from '@pbx/shared/ui/common/trust-pilot-stars';
import { Link } from '@pbx/shared/ui/common/link';

import { TReviewCard } from '../review-block.types';

export const ReviewCard = ({
  stars,
  title,
  review,
  createdAt,
  linkUrl,
  author,
}: TReviewCard) => {
  return (
    <div className="rounded-medium border-visual bg-background-primary flex min-w-[240px] select-none flex-col gap-6 p-4">
      <div className="flex sm:flex-col sm:gap-4 md:flex-col md:gap-4 lg:flex-row lg:justify-between">
        <Link
          newTab={true}
          href="https://www.trustpilot.com/review/purplebricks.com"
        >
          <img
            width={130}
            height={32}
            alt="trustpilot-logo"
            src={`${process.env.NEXT_PUBLIC_PB_IMGIX_URL}/trustpilot-logo-black.svg`}
          />
        </Link>
        <TrustPilotStars starRating={stars} size={24} />
      </div>

      <div className="flex flex-col gap-3">
        <Text
          variant="heading"
          size="smaller"
          className="text-foreground-highlight line-clamp-1"
        >
          {title}
        </Text>
        <Text
          variant="paragraph"
          size="medium"
          className="line-clamp-2 min-h-[56px]"
        >
          {review}
        </Text>
        <Link
          className="text-button-tertiary-foreground w-fit underline"
          href={linkUrl}
        >
          <Text variant="paragraph" size="default-400">
            Read more
          </Text>
        </Link>
        <div className="flex flex-col gap-1">
          <Text
            variant="heading"
            size="smallest"
            className="text-foreground-primary"
          >
            {author}
          </Text>
          <Text
            variant="paragraph"
            size="small-tight"
            className="text-foreground-secondary"
          >
            {formatDistance(new Date(), parseISO(createdAt))} ago
          </Text>
        </div>
      </div>
    </div>
  );
};
