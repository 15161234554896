import { cva } from 'class-variance-authority';

import { textVariants } from '@pbx/shared/ui/common/text';

export const listItemVariants = cva('', {
  variants: {
    appearance: {
      default: 'text-foreground-primary',
      brand: 'text-brand-foreground-secondary',
      mortgagePrimary: 'text-background-secondary',
      mortgageSecondary: 'text-foreground-secondary',
    },
    listStyle: {
      highlight: '',
      positive: '',
    },
    size: {
      default: `${textVariants({ variant: 'paragraph', size: 'medium-tight' })}`,
      large: `${textVariants({ variant: 'paragraph', size: 'large' })}`,
    },
  },
});

export const iconVariants = cva('', {
  variants: {
    appearance: {
      default: '',
      brand: '',
      mortgagePrimary: '',
      mortgageSecondary: '',
    },
    listStyle: {
      highlight: '',
      positive: '',
    },
    size: {
      default: 'min-h-[24px] min-w-[24px]',
      large: 'min-h-[32px] min-w-[32px]',
    },
  },
  compoundVariants: [
    {
      appearance: 'default',
      listStyle: 'highlight',
      class: 'text-foreground-highlight',
    },
    {
      appearance: 'default',
      listStyle: 'positive',
      class: 'text-foreground-positive',
    },
    {
      appearance: 'brand',
      listStyle: 'highlight',
      class: 'text-brand-foreground-highlight',
    },
    {
      appearance: 'brand',
      listStyle: 'positive',
      class: 'text-brand-foreground-positive',
    },
    {
      appearance: 'mortgagePrimary',
      listStyle: 'positive',
      class: 'text-brand-foreground-positive',
    },
    {
      appearance: 'mortgageSecondary',
      listStyle: 'positive',
      class: 'text-foreground-positive',
    },
  ],
});
