import { cva } from 'class-variance-authority';

export const rangeVariants = cva('rounded-large absolute h-full', {
  variants: {
    variant: {
      default: 'bg-foreground-action',
      brand: 'bg-brand-foreground-highlight',
    },
  },
});

export const thumbVariants = cva(
  'block h-[28px] w-[28px] rounded-[14px] hover:h-[32px] hover:w-[32px] hover:rounded-[16px] focus:outline-none active:h-[24px] active:w-[24px] active:rounded-[12px] focus:h-[24px] focus:w-[24px] focus:rounded-[12px]',
  {
    variants: {
      variant: {
        default:
          'bg-foreground-action active:shadow-[0px_0px_0px_8px_rgba(192,33,168,0.20)] focus:shadow-[0px_0px_0px_8px_rgba(192,33,168,0.20)]',
        brand:
          'bg-brand-foreground-highlight active:shadow-[0px_0px_0px_8px_rgba(255,203,31,0.20)] focus:shadow-[0px_0px_0px_8px_rgba(255,203,31,0.20)]',
      },
    },
  }
);
