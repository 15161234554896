'use client';

import { Accordion } from '@pbx/shared/ui/common/accordion';
import { Text } from '@pbx/shared/ui/common/text';
import { RichText } from '@pbx/shared/ui/common/rich-text';

import { TFAQBlockProps } from './faq-block.types';

export function FAQBlock({
  title,
  description,
  faqItems = [],
}: TFAQBlockProps) {
  const accordionItems = faqItems.map((item) => ({
    title: item.question,
    content: <RichText text={item.answer} disableMargins />,
  }));

  return (
    <div className="container flex sm:flex-col sm:gap-4 md:flex-col md:gap-6 lg:flex-row lg:gap-8">
      <div className="flex flex-col gap-4 sm:basis-full md:basis-full lg:basis-5/12">
        <Text as="h2" variant="heading" size="large" className="max-w-[728px]">
          {title}
        </Text>
        {description && (
          <Text
            as="p"
            variant="paragraph"
            size="medium"
            className="text-foreground-secondary max-w-[620px]"
          >
            {description}
          </Text>
        )}
      </div>
      <div className="flex sm:basis-full md:basis-full lg:basis-7/12">
        <Accordion items={accordionItems} type="multiple" />
      </div>
    </div>
  );
}
