import React from 'react';

import { ProgressCircle } from '@pbx/shared/ui/common/progress-circle';
import { calculateLoanToValue } from '@pbx/shared/utilities/calculate-loan-to-value';

export function LTVChart({
  propertyValue,
  deposit,
}: {
  propertyValue?: number;
  deposit?: number;
}) {
  const ltvValue = calculateLoanToValue(propertyValue, deposit);
  const progressMaxValue = propertyValue ? propertyValue : undefined;

  return (
    <ProgressCircle max={progressMaxValue} value={deposit}>
      LTV
      {ltvValue > 0 && (
        <>
          :<span className="text-foreground-highlight">{` ${ltvValue}%`}</span>
        </>
      )}
    </ProgressCircle>
  );
}
