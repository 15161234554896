import { useFormContext } from 'react-hook-form';
import React from 'react';

import { FormField } from '@pbx/shared/ui/common/form/form';
import { EFieldName } from '@pbx/shared/api-client/services/mortgages';
import { Input } from '@pbx/shared/ui/common/form/input';

import { TUseFormReturn } from '../lead-capture-form.types';

export function PersonalDetails() {
  const { control } = useFormContext<TUseFormReturn>();

  return (
    <>
      <div className="flex items-start gap-4 sm:flex-col md:flex-row lg:flex-row">
        <FormField
          control={control}
          name={EFieldName.FIRST_NAME}
          className="w-full"
        >
          <Input label="First Name" className="w-full" />
        </FormField>
        <FormField
          control={control}
          name={EFieldName.LAST_NAME}
          className="w-full"
        >
          <Input label="Last Name" className="w-full" />
        </FormField>
      </div>
      <div className="flex items-start gap-4 sm:flex-col md:flex-row lg:flex-row">
        <FormField
          control={control}
          name={EFieldName.PHONE_NUMBER}
          className="w-full"
        >
          <Input label="Phone Number" className="w-full" />
        </FormField>
        <FormField control={control} name={EFieldName.EMAIL} className="w-full">
          <Input label="Email" className="w-full" />
        </FormField>
      </div>
    </>
  );
}
