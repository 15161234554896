import { cn } from '@pbx/shared/utilities/combine-class-names';

import { TShapeHouse } from './shape.types';
import { shapeVariants } from './shape.variants';

export const ShapeHouse = ({ children, shape, className }: TShapeHouse) => {
  return (
    <div
      className={cn(shapeVariants({ shape }), className)}
      data-testid={`shape-wrapper-${shape}`}
    >
      {children}
    </div>
  );
};
