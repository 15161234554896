export type TCallbackAppointments = {
  [key in ECallbackAvailability]?: TAppointment[];
};

export type TAppointment = {
  date: string;
  times: TTime[] | null;
};

export type TTime = {
  from: string;
};

export enum ECallbackAgentType {
  HELPLINE = 'Helpline',
  MORTGAGE_ADVISOR = 'MortgageAdvisor',
  REMORTGAGE_ADVISOR = 'RemortgageAdvisor',
}

export enum ECallbackAvailability {
  HELPLINE_AVAILABILITY = 'helplineAvailability',
  MORTGAGE_ADVISOR_AVAILABILITY = 'mortgageAdvisorAvailability',
  REMORTGAGE_ADVISOR_AVAILABILITY = 'remortgageAdvisorAvailability',
}
