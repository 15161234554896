import { useWindowSize } from 'react-use';

import { TScreenSize } from './use-media-size.types';

/*
 * This needs to match Tailwind breakpoints
 * inside: styles/core/lib/screens.ts
 */
export const screenTokens = {
  sm: { min: 0, max: 599 },
  md: { min: 600, max: 1151 },
  lg: { min: 1152 },
};

export const useMediaSize = () => {
  const { width } = useWindowSize();

  const getScreenSize = (width: number): TScreenSize => {
    if (width >= screenTokens.sm.min && width <= screenTokens.sm.max) {
      return 'isSm';
    }

    if (width >= screenTokens.md.min && width <= screenTokens.md.max) {
      return 'isMd';
    }

    return 'isLg';
  };

  const deviceSize = getScreenSize(width);

  return {
    isSm: deviceSize === 'isSm',
    isMd: deviceSize === 'isMd',
    isLg: deviceSize === 'isLg',
  };
};
