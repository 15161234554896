import React from 'react';

import { Button } from '@pbx/shared/ui/common/button';
import { Link } from '@pbx/shared/ui/common/link';

import { TLinkButtonProps } from './link-button.types';

export function LinkButton({
  variant,
  inverted = false,
  link,
  newTab = false,
  label,
  icon,
  iconPosition,
  size,
  className,
}: TLinkButtonProps) {
  return (
    <Button
      variant={variant}
      inverted={inverted}
      icon={icon}
      iconPosition={iconPosition}
      asChild
      className={className}
      size={size}
    >
      <Link href={link?.href || ''} newTab={newTab}>
        {label}
      </Link>
    </Button>
  );
}
