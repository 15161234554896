'use client';
import { useMemo, createContext } from 'react';

import { TEventData } from './trackers/google-analytics-tracker/google-analytics-tracker.types';
import { TAnalyticsEvents, TAnalyticsProviderProps } from './analytics.types';
import { googleAnalyticsTracker } from './trackers/google-analytics-tracker/google-analytics-tracker';
import { TGoogleAnalyticsTracker } from './trackers/google-analytics-tracker/google-analytics-tracker.types';
import { createAnalyticsService } from './create-analytics-service';
import { TAnalyticsContextProps } from './analytics.types';

export const AnalyticsContext = createContext<
  TAnalyticsContextProps | undefined
>(undefined);

export const AnalyticsProvider = ({ children }: TAnalyticsProviderProps) => {
  const getGAService = useMemo(
    () =>
      createAnalyticsService<TGoogleAnalyticsTracker>(
        googleAnalyticsTracker,
        []
      ),
    []
  );

  const trackGAEvent = (event: string, data: TEventData) => {
    return getGAService().eventTracking(event, data);
  };

  const trackGAFormEvent = {
    trackSubmit: getGAService().trackSubmit,
    trackSubmitError: getGAService().trackSubmitError,
    trackInputChange: getGAService().trackInputChange,
  };

  const analyticsTracker: TAnalyticsEvents = {
    trackGAEvent,
    trackGAFormEvent,
  };

  return (
    <AnalyticsContext.Provider value={{ analyticsTracker }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
