'use client';

import { Text } from '@pbx/shared/ui/common/text';
import { LinkButton } from '@pbx/shared/ui/common/link-button';

export function PostCodeNotFound() {
  const basePath = process.env.NEXT_PUBLIC_PB_BASE_PATH || '';

  return (
    <div className="flex flex-col gap-4 lg:max-w-[620px]">
      <Text
        variant="heading"
        size="small"
        className="text-foreground-highlight"
        as="h3"
      >
        Sorry, we can’t find your postcode, but don’t worry
      </Text>
      <Text as="p">
        We want to make sure we can get our price absolutely spot on so please
        call our call centre so that we can tailor the best plan for you.
      </Text>
      <div className="flex flex-col gap-1">
        <Text as="p">Call us directly on:</Text>
        <Text
          variant="heading"
          size="medium"
          className="text-foreground-highlight mt-0"
        >
          0800 810 8008
        </Text>
      </div>
      <Text as="p">
        9am – 6pm between Monday and Friday, and from 9am – 5pm on Saturday, and
        from 9am – 4:30pm on Sunday.
      </Text>
      <LinkButton
        variant="primary"
        newTab={false}
        label="Contact us"
        link={{ href: `${basePath}/contact-us` }}
        className="sm:w-full md:w-fit lg:w-fit"
      />
    </div>
  );
}
