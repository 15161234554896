import { cva } from 'class-variance-authority';

import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Label } from '@pbx/shared/ui/common/form/label';
import { Error } from '@pbx/shared/ui/common/form/error';

import {
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
} from './select.components';
import { TSelectProps, TSelectOptionGroup } from './select.types';

function renderOptions(options: TSelectOptionGroup[], isGrouped = false) {
  return options.map((option) => {
    if (option.options) {
      return (
        <SelectGroup key={option.value || option.label}>
          {option.label && <SelectLabel>{option.label}</SelectLabel>}
          {renderOptions(option.options, true)}
        </SelectGroup>
      );
    }
    return (
      <SelectItem
        key={option.value}
        value={option.value ?? ''}
        className={isGrouped ? 'pl-4' : ''}
      >
        {option.label}
      </SelectItem>
    );
  });
}

export function Select({
  options = [],
  onChange,
  placeholder = '',
  className = '',
  label = '',
  helper,
  id,
  disabled,
  error,
  ...props
}: TSelectProps) {
  const variants = cva('', {
    variants: {
      variant: {
        default: '',
        error: 'border-error',
        disabled:
          'border-border-disabled bg-background-disabled placeholder-foreground-disabled text-foreground-disabled cursor-not-allowed',
      },
    },
  });
  function getVariant() {
    if (disabled) {
      return 'disabled';
    }
    if (error) {
      return 'error';
    }
    return undefined;
  }

  return (
    <div className={cn('inline-flex flex-col', className)}>
      {label && (
        <Label helper={helper} htmlFor={id} className="mb-3">
          {label}
        </Label>
      )}
      <SelectRoot onValueChange={onChange} disabled={disabled} {...props}>
        <SelectTrigger
          className={cn(variants({ variant: getVariant() }), className)}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>{renderOptions(options)}</SelectContent>
      </SelectRoot>
      {error && <Error className="mt-2">{error}</Error>}
    </div>
  );
}
