'use client';
import React from 'react';

import { LeadCaptureFormBlock } from '@pbx/shared/ui/blocks/lead-capture-form-block';
import { useCallbackRequests } from '@pbx/shared/api-client/services/mortgages';

import { TLeadCaptureFormBlockContainerProps } from './lead-capture-form-block-container.types';

export function LeadCaptureFormBlockContainer({
  title,
  description,
  featureList,
}: TLeadCaptureFormBlockContainerProps) {
  const {
    callbackAppointments,
    setAgentType,
    mutate,
    isFormSubmitting,
    isAppointmentsLoading,
    isSuccess,
    isError,
  } = useCallbackRequests();

  return (
    <LeadCaptureFormBlock
      title={title}
      description={description}
      featureList={featureList}
      onFormSubmit={mutate}
      isLoading={isFormSubmitting}
      isAppointmentsLoading={isAppointmentsLoading}
      isSuccess={isSuccess}
      isError={isError}
      callbackAppointments={callbackAppointments}
      fetchAppointments={setAgentType}
    />
  );
}
