import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { TLocation } from '../location.types';
import { GET_LOCATIONS } from '../location.constants';

export async function getLocations() {
  return await fetchApi<TLocation[]>(
    `${process.env.NEXT_PUBLIC_PB_BASE_API_URL}/statistic-api/v1/location`,
    {
      mode: 'cors',
    }
  );
}

export function useGetLocations(config: TUseQueryOptions<TLocation[]> = {}) {
  return useQuery({
    queryKey: [GET_LOCATIONS],
    queryFn: () => getLocations(),
    ...config,
  });
}
