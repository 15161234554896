/**
 * Adjusts a given time string by a specified timezone offset (in minutes),
 * ensuring the result is formatted as `HH:MM:SS` and accounts for day wraparound.
 *
 * @param {string} time - The initial time in `HH:MM` format.
 * @param {number} timezoneOffsetMinutes - The timezone offset in minutes (can be positive or negative).
 *
 * @returns {string} - The adjusted time in `HH:MM:SS` format.
 *
 * @example
 * // Adjusts 23:30 by +60 minutes, resulting in 00:30:00 (next day).
 * addTimezoneOffsetToTime("23:30", 60); // "00:30:00"
 *
 * @example
 * // Adjusts 00:30 by -120 minutes, resulting in 22:30:00 (previous day).
 * addTimezoneOffsetToTime("00:30", -120); // "22:30:00"
 */
export function addTimezoneOffsetToTime(
  time: string,
  timezoneOffsetMinutes: number
) {
  const [hour, minutes] = time.split(':');
  // add 24 and use remainder to handling cases where the hour becomes negative or exceeds 23.
  const adjustedHour =
    (parseInt(hour) + Math.floor(timezoneOffsetMinutes / 60) + 24) % 24;

  const hourWithOffset = adjustedHour.toString().padStart(2, '0');

  return `${hourWithOffset}:${minutes}:00`;
}
