export type TShapeHouse = {
  children: React.ReactNode;
  shape?: EShapeVariants;
  className?: string;
};

export enum EShapeVariants {
  NONE = 'none',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
}
