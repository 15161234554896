import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { TPriceByPostcode } from '../products.types';
import { GET_PRICE_BY_POSTCODE } from '../products.constants';

export async function getPriceByPostcode(postcode: string) {
  return await fetchApi<TPriceByPostcode>(
    `${process.env.NEXT_PUBLIC_COMMERCE_ADDON_BASE_URL}/api/V3/PriceCheck/${postcode}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetPriceByPostcode(
  postcode: string,
  config: TUseQueryOptions<TPriceByPostcode> = {}
) {
  return useQuery({
    queryKey: [GET_PRICE_BY_POSTCODE, postcode],
    queryFn: () => getPriceByPostcode(postcode),
    ...config,
  });
}
