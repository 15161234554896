import React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { Label } from '@pbx/shared/ui/common/form/label';
import { Error } from '@pbx/shared/ui/common/form/error';
import { cn } from '@pbx/shared/utilities/combine-class-names';

import {
  RadioGroup as RadioGroupRadix,
  RadioGroupItem as RadioGroupItemRadix,
} from './radio-group.components';
import { TRadioGroupProps } from './radio-group.types';
import { variants } from './radio-group.variants';

export const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  TRadioGroupProps
>(({ options, label, className, disabled, error, onChange, ...props }, _) => {
  const getVariant = () => {
    if (disabled) return 'disabled';

    if (error) return 'error';

    return undefined;
  };

  return (
    <div className={cn('flex flex-col gap-6', className)}>
      {label && <Label>{label}</Label>}
      <RadioGroupRadix
        className="gap-6"
        onValueChange={onChange}
        disabled={disabled}
        {...props}
      >
        {options.map((option, index) => (
          <div
            className="flex items-center space-x-3"
            key={`radio-group-item-${index}`}
          >
            <RadioGroupItemRadix
              value={option.value}
              id={option.value}
              className={variants({ variant: getVariant() })}
              data-testid={`radio-group-${option.value}-option`}
            />
            <Label htmlFor={option.value}>{option.label}</Label>
          </div>
        ))}
      </RadioGroupRadix>
      {error && <Error>{error}</Error>}
    </div>
  );
});
