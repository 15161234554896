'use client';
import { useState } from 'react';

import {
  EmblaCarousel,
  CarouselContent,
  CarouselItem,
} from '@pbx/shared/ui/common/carousel';
import { screenTokens } from '@pbx/styles/core/screen';

import { ReviewCard } from './review-card';
import { TReviewsCarousel } from '../review-block.types';

export const ReviewsCarousel = ({ reviews }: TReviewsCarousel) => {
  const itemsToShowByScreenSize = { md: 2, default: 3 };
  const [itemsToShow, setItemsToShow] = useState(
    itemsToShowByScreenSize.default
  );

  const breakpoints = {
    [`(min-width: ${screenTokens.md.min})`]: { watchDrag: false },
  };
  const mdQuery = window.matchMedia(
    `(min-width: ${screenTokens.md.min}) and (max-width: ${screenTokens.md.max})`
  );

  const handleResize = () => {
    setItemsToShow(
      mdQuery.matches
        ? itemsToShowByScreenSize.md
        : itemsToShowByScreenSize.default
    );
  };

  return (
    <EmblaCarousel
      setApi={(emblaApi) => {
        if (emblaApi) {
          handleResize();
          emblaApi.on('resize', handleResize);
        }
      }}
      opts={{
        dragFree: true,
        breakpoints,
      }}
    >
      <CarouselContent className="flex gap-8 sm:px-4">
        {reviews?.slice(0, itemsToShow).map((review, index) => (
          <CarouselItem
            key={`${index}-${review.id}`}
            className="md:flex-1 lg:flex-1"
          >
            <ReviewCard
              stars={review.stars}
              author={review.author}
              review={review.review}
              title={review.title}
              createdAt={review.createdAt}
              linkUrl={review.linkUrl}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
    </EmblaCarousel>
  );
};
