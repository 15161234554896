'use client';

import React, { FocusEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form, FormField } from '@pbx/shared/ui/common/form/form';
import { ToggleSelect } from '@pbx/shared/ui/common/form/toggle-select';
import { Checkbox } from '@pbx/shared/ui/common/form/checkbox';
import { Button } from '@pbx/shared/ui/common/button';
import {
  createCallbackRequestSchema as formSchema,
  ECallbackAgentType,
  EFieldName,
  transformPurchaseData,
  transformRemortgageData,
} from '@pbx/shared/api-client/services/mortgages';
import { Error } from '@pbx/shared/ui/common/form/error';
import { useAnalytics } from '@pbx/shared/analytics';

import {
  TLeadCaptureFormProps,
  TUseFormReturn,
} from './lead-capture-form.types';
import { PurchaseJourney } from './journeys/purchase-journey';
import { ReMortgageJourney } from './journeys/re-mortgage-journey';
import { Calendar } from './calendar/calendar';
import { PleaseReadMessage } from './messages/please-read-message';
import { PersonalDetails } from './personal-details/personal-details';
import { useLeadCaptureForm } from './use-lead-capture-form/use-lead-capture-form';
import {
  ACCEPT_TCS_LABEL,
  RADIO_FIELD_LABELS,
  SUBMIT_ERROR_MESSAGE,
  WHAT_DO_YOU_NEED_OPTIONS,
} from './lead-capture-form.constants';
import {
  createSubmitTrackingDataObject,
  getCallbackAgentType,
  getFieldName,
} from './lead-capture-form.helpers';

export function LeadCaptureForm({
  initialValues,
  onFormSubmit,
  isLoading = false,
  isAppointmentsLoading = false,
  isError = false,
  callbackAppointments = {},
  fetchAppointments,
}: TLeadCaptureFormProps) {
  const form = useForm<TUseFormReturn>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...initialValues,
    },
  });
  const [trackedFields, setTrackedFields] = useState<string[]>([]);

  const {
    callbackAvailability,
    callDate,
    deposit,
    isPurchase,
    isReMortgage,
    isSubmitButtonDisabled,
    propertyValue,
    showCalendar,
    showTCs,
    showWhatDoYouNeedSelector,
    purchaseJourney,
    isDirty,
    whereAreYouAt,
  } = useLeadCaptureForm({
    form,
    fetchAppointments,
    isLoading,
  });

  const {
    trackGAFormEvent: { trackInputChange, trackSubmitError, trackSubmit },
  } = useAnalytics();

  const filteredAppointments = callbackAvailability
    ? callbackAppointments[callbackAvailability]
    : undefined;

  const onSubmit = (values: TUseFormReturn) => {
    const agentType = getCallbackAgentType({
      isPurchase,
      purchaseStep: whereAreYouAt,
    }) as ECallbackAgentType;

    const fieldValues = isPurchase
      ? transformPurchaseData(values, agentType)
      : transformRemortgageData(values);

    onFormSubmit &&
      onFormSubmit(fieldValues, {
        onSuccess: (data) =>
          trackSubmit(createSubmitTrackingDataObject(values, data?.zohoId)),
        onError: (error) => trackSubmitError(error),
      });
  };

  const onFieldBlur = useCallback(
    (e: FocusEvent<HTMLFormElement>) => {
      const fieldName = getFieldName(e);

      if (fieldName && !trackedFields.includes(fieldName)) {
        trackInputChange(e.target, RADIO_FIELD_LABELS);
        setTrackedFields((prevState) => [...prevState, fieldName]);
      }
    },
    [trackInputChange, trackedFields]
  );

  useEffect(() => {
    // If there is data in local storage, reset the form to prefill the values in local storage
    if (initialValues && !isDirty) {
      form.reset({ ...initialValues });
    }
  }, [initialValues, isDirty, form]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="bg-background-primary lg:p-13 space-y-8"
        onBlur={onFieldBlur}
      >
        <PersonalDetails />
        {showWhatDoYouNeedSelector && (
          <FormField control={form.control} name={EFieldName.WHAT_DO_YOU_NEED}>
            <ToggleSelect
              columns={[2, 2, 2]}
              label="What are you looking to do?"
              type="single"
              className="w-full"
              options={WHAT_DO_YOU_NEED_OPTIONS}
            />
          </FormField>
        )}
        {isPurchase && (
          <PurchaseJourney
            {...purchaseJourney}
            propertyValue={propertyValue}
            deposit={deposit}
          />
        )}
        {isReMortgage && <ReMortgageJourney />}
        {showCalendar && (
          <Calendar
            callDate={callDate}
            callbackAppointments={filteredAppointments}
            isLoading={isAppointmentsLoading}
          />
        )}
        {showTCs && (
          <>
            <PleaseReadMessage />
            <FormField control={form.control} name={EFieldName.ACCEPT_TCS}>
              <Checkbox label={ACCEPT_TCS_LABEL} />
            </FormField>
          </>
        )}
        {isError && <Error className="mt-3">{SUBMIT_ERROR_MESSAGE}</Error>}
        <Button type="submit" disabled={isSubmitButtonDisabled}>
          {isLoading ? 'Submitting' : 'Continue'}
        </Button>
      </form>
    </Form>
  );
}
