import { TIconName } from '@pbx/shared/ui/common/icon';
import { TFeatureListProps } from '@pbx/shared/ui/common/feature-list';
import { TButton } from '@pbx/shared/contentful/helpers';

export enum EServiceCardState {
  DEFAULT = 'Default',
  HIGHLIGHTED = 'Highlighted',
  DISABLED = 'Disabled',
}

export type TServiceCardProps = {
  title: string;
  state: EServiceCardState;
  subtitle?: string;
  description?: string;
  tag?: string;
  icon?: TIconName;
  featureList?: TFeatureListProps;
  preListText?: string;
  postListText?: string;
  button?: TButton;
  className?: string;
};
