'use client';

import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Icon } from '@pbx/shared/ui/common/icon';
import { Label } from '@pbx/shared/ui/common/form/label';
import { Error } from '@pbx/shared/ui/common/form/error';

import { variants } from './checkbox.variants';
import { TCheckboxProps } from './checkbox.types';

export const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  TCheckboxProps
>(
  (
    {
      className,
      error,
      disabled,
      label,
      helper,
      id,
      defaultChecked,
      labelSize = 'default-500',
      value,
      onChange,
      ...props
    },
    ref
  ) => {
    function getVariant() {
      if (disabled) return 'disabled';
      if (error) return 'error';

      return undefined;
    }

    return (
      <div className={cn('inline-flex gap-3', className)}>
        <div className="flex h-[32px] w-[32px] items-center justify-center">
          <CheckboxPrimitive.Root
            ref={ref}
            className={cn(
              'border-border-contrast rounded-medium bg-background-primary data-[state=checked]:bg-background-active data-[state=checked]:text-foreground-contrast disabled:bg-background-disabled focus-visible:border-input-focus peer h-7 w-7 shrink-0 border outline-none disabled:cursor-not-allowed disabled:opacity-80 data-[state=checked]:border-0',
              variants({ variant: getVariant() }),
              className
            )}
            disabled={disabled}
            defaultChecked={defaultChecked}
            id={id}
            checked={value}
            onCheckedChange={onChange}
            {...props}
          >
            <CheckboxPrimitive.Indicator
              className={cn('flex items-center justify-center text-current')}
            >
              <Icon name="tick" width={20} strokeWidth={2.5} />
            </CheckboxPrimitive.Indicator>
          </CheckboxPrimitive.Root>
        </div>
        <div className="flex flex-col gap-3 pt-[6px]">
          {(!!label || !!helper) && (
            <Label
              helper={helper}
              htmlFor={id}
              labelSize={labelSize}
              helperSize="small-tight"
            >
              {label}
            </Label>
          )}
          {error && <Error>{error}</Error>}
        </div>
      </div>
    );
  }
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
