import { fetchApi } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { ECallbackAgentType, TCallbackAppointments } from '../mortgages.types';
import { GET_CALLBACK_APPOINTMENTS } from '../mortgages.constants';
import { TUseGetCallbackAppointmentsProps } from './get-callback-appointments.types';

export async function getCallbackAppointments(
  callbackAgentType: ECallbackAgentType | null
) {
  const queryParams = callbackAgentType
    ? `?callbackAgentType=${callbackAgentType}`
    : '';

  return await fetchApi<TCallbackAppointments>(
    `${process.env.NEXT_PUBLIC_PB_BASE_API_URL}/mortgage-referrals-api/v1/callbackappointment${queryParams}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetCallbackAppointments({
  callbackAgentType = null,
  config = {},
}: TUseGetCallbackAppointmentsProps = {}) {
  return useQuery({
    queryKey: [GET_CALLBACK_APPOINTMENTS, callbackAgentType || 'all'],
    queryFn: () => getCallbackAppointments(callbackAgentType),
    ...config,
  });
}
