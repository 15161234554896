'use client';

import * as React from 'react';
import { PropsWithChildren, useCallback } from 'react';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@pbx/shared/ui/common/popover';
import { Calendar } from '@pbx/shared/ui/common/calendar';

import { TDatePickerProps } from './date-picker.types';

export function DatePicker({
  onChange,
  children,
  align = 'center',
  asChild = true,
  onOpenChange,
  disabled,
}: PropsWithChildren<TDatePickerProps>) {
  const [date, setDate] = React.useState<Date>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const onValueChange = useCallback(
    (value: Date | undefined) => {
      setDate(value);
      onChange && onChange(value);
      setIsOpen(false);
    },
    [onChange]
  );

  const onCalendarOpenChange = useCallback(
    (isOpen: boolean) => {
      setIsOpen(isOpen);
      onOpenChange && onOpenChange(isOpen);
    },
    [onOpenChange]
  );

  return (
    <Popover onOpenChange={onCalendarOpenChange} open={isOpen}>
      <PopoverTrigger asChild={asChild}>
        <div>{children}</div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align={align}>
        <Calendar
          mode="single"
          selected={date}
          onSelect={onValueChange}
          disabled={disabled}
        />
      </PopoverContent>
    </Popover>
  );
}
