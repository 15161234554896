import { cva } from 'class-variance-authority';

import { EShapeVariants } from './shape.types';

export const shapeVariants = cva('w-fit h-fit', {
  variants: {
    shape: {
      [EShapeVariants.NONE]: 'max-w-full',
      [EShapeVariants.ONE]: 'house-shape-1',
      [EShapeVariants.TWO]: 'house-shape-2',
      [EShapeVariants.THREE]: 'house-shape-3',
      [EShapeVariants.FOUR]: 'house-shape-4',
      [EShapeVariants.FIVE]: 'house-shape-5',
      [EShapeVariants.SIX]: 'house-shape-6',
      [EShapeVariants.SEVEN]: 'house-shape-7',
      [EShapeVariants.EIGHT]: 'house-shape-8',
      [EShapeVariants.NINE]: 'house-shape-9',
    },
  },
});
