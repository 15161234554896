export function getHeightByMediaSize({
  isSm,
  isMd,
  heightMobile,
  heightTablet,
  heightDesktop,
}: {
  isSm: boolean;
  isMd: boolean;
  heightMobile: string;
  heightTablet: string;
  heightDesktop: string;
}): string {
  if (isSm) {
    return heightMobile;
  }

  if (isMd) {
    return heightTablet;
  }

  return heightDesktop;
}
