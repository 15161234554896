import { cva } from 'class-variance-authority';

export const variants = cva('', {
  variants: {
    hasBackground: {
      true: 'bg-background-secondary lg:p-8 md:p-8 sm:px-4 sm:py-9',
      false: '',
    },
  },
});
