import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { cn } from '@pbx/shared/utilities/combine-class-names';

export const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn(
      'border-b-border-visual flex flex-col gap-3 border-b py-4',
      className
    )}
    {...props}
  />
));

AccordionItem.displayName = 'AccordionItem';
