import { cva } from 'class-variance-authority';

import { textVariants } from '@pbx/shared/ui/common/text';

export const variants = cva(
  `${textVariants({ variant: 'ui', size: 'medium-500' })} focus-visible:border-input-focus focus-visible:data-[state=checked]:border-input-active  data-[state=checked]:border-border-active data-[state=checked]:bg-background-active h-8 w-8 rounded-[50%] outline-none`,
  {
    variants: {
      variant: {
        default:
          'border-border-contrast shadow-low data-[state=checked]:shadow-none',
        error:
          'border-border-error data-[state=checked]:bg-background-error data-[state=checked]:border-border-error text-foreground-error shadow-low data-[state=checked]:shadow-none',
        disabled:
          'border-border-disabled data-[state=checked]:bg-background-disabled data-[state=checked]:border-0 data-[state=checked]:shadow-none cursor-not-allowed',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
