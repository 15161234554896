import { cva } from 'class-variance-authority';

import { textVariants } from '@pbx/shared/ui/common/text';

export const variants = cva(
  `${textVariants({ variant: 'ui', size: 'medium-500' })} rounded-medium text-foreground-action focus-visible:border-input-focus focus-visible:data-[state=on]:border-input-active h-12 border p-3 outline-none w-full`,
  {
    variants: {
      variant: {
        default:
          'bg-background-primary border-border-visual data-[state=on]:border-border-active data-[state=on]:bg-background-active shadow-low data-[state=on]:shadow-none data-[state=on]:text-foreground-contrast',
        error:
          'bg-background-primary border-border-error data-[state=on]:text-foreground-contrast data-[state=on]:bg-background-error text-foreground-error shadow-[shadow:var(--box-shadow-low),inset_0px_0px_0px_1px_var(--border-error)] data-[state=on]:shadow-none',
        disabled:
          'border-border-disabled data-[state=on]:bg-background-disabled data-[state=on]:border-0 placeholder-foreground-disabled text-foreground-disabled cursor-not-allowed',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
