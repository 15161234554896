import { ReactNode } from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';

import { Image } from '@pbx/shared/ui/common/image';
import { Blockquote } from '@pbx/shared/ui/common/blockquote';
import { cn } from '@pbx/shared/utilities/combine-class-names';
import { getContentType } from '@pbx/shared/contentful/helpers';
import { TEntry } from '@pbx/shared/contentful/types';

import {
  TAppearance,
  TCustomClasses,
  TOptions,
  TRichTextComponentsDictionary,
} from './rich-text.types';
import { variants } from './rich-text.variants';

export const getOptions = ({
  customClasses,
  appearance = 'default',
  componentMap,
}: {
  customClasses?: TCustomClasses | undefined;
  appearance?: TAppearance;
  componentMap?: TRichTextComponentsDictionary;
}): TOptions => ({
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <strong className={customClasses?.b}>{text}</strong>
    ),
    [MARKS.ITALIC]: (text) => <i className={customClasses?.em}>{text}</i>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p
        className={cn(
          variants({ variant: 'paragraph', type: 'paragraph' }),
          customClasses?.p
        )}
      >
        {children}
      </p>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1
        className={cn(
          variants({ variant: 'h1', type: 'heading' }),
          customClasses?.h1
        )}
      >
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2
        className={cn(
          variants({ variant: 'h2', type: 'heading' }),
          customClasses?.h2
        )}
      >
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3
        className={cn(
          variants({ variant: 'h3', type: 'heading' }),
          customClasses?.h3
        )}
      >
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4
        className={cn(
          variants({ variant: 'h4', type: 'heading' }),
          customClasses?.h4
        )}
      >
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5
        className={cn(
          variants({ variant: 'h5', type: 'heading' }),
          customClasses?.h5
        )}
      >
        {children}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6
        className={cn(
          variants({ variant: 'h6', type: 'heading' }),
          customClasses?.h6
        )}
      >
        {children}
      </h6>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className={cn(variants({ variant: 'ul' }), customClasses?.ul)}>
        {children}
      </ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className={cn(variants({ variant: 'ol' }), customClasses?.ol)}>
        {children}
      </ol>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className={cn(variants({ variant: 'li' }), customClasses?.li)}>
        {clearNestedClasses(children)}
      </li>
    ),
    [BLOCKS.QUOTE]: (node, children) => {
      return (
        <Blockquote
          className={cn(
            variants({ variant: 'quote' }),
            customClasses?.blockquote
          )}
        >
          {clearNestedClasses(children)}
        </Blockquote>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, file } = node?.data?.target?.fields || {};

      if (!file) return null;

      const {
        image: { width, height },
      } = file.details;

      return (
        <Image
          src={file?.url}
          alt={title}
          width={width}
          height={height}
          className={cn(variants({ variant: 'img' }), 'max-w-full')}
        />
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      if (!componentMap) return null;

      const { target } = node.data;
      const { Component, props, type } = getEmbeddedEntryData(
        target,
        componentMap
      );

      return Component ? (
        <div className={cn('mb-4', { 'icon-container': type === 'icon' })}>
          <Component {...props} />
        </div>
      ) : null;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data;
      return (
        <a
          href={uri}
          className={cn(
            variants({ variant: 'a', appearance }),
            customClasses?.a
          )}
        >
          {children}
        </a>
      );
    },
    [BLOCKS.TABLE]: (node, children) => (
      <div className="rounded-large border-border-contrast mb-4 overflow-hidden border">
        <table className={cn(variants({ variant: 'table' }))}>
          <tbody>{children}</tbody>
        </table>
      </div>
    ),
    [BLOCKS.TABLE_ROW]: (node, children) => (
      <tr className={cn(variants({ variant: 'table-row' }))}>{children}</tr>
    ),
    [BLOCKS.TABLE_CELL]: (node, children) => (
      <td className={cn(variants({ variant: 'table-cell' }))}>
        {clearNestedClasses(children)}
      </td>
    ),
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
      <th className={cn(variants({ variant: 'table-header-cell' }))}>
        {clearNestedClasses(children)}
      </th>
    ),
  },
  renderText: renderTextWithLineBreaks,
});

export function clearNestedClasses(children: ReactNode) {
  if (Array.isArray(children)) {
    return children.map((child) => ({
      ...child,
      props: {
        ...child?.props,
        className: undefined,
      },
    }));
  }

  return children;
}

export function getEmbeddedEntryData(
  entry: TEntry,
  componentMap: TRichTextComponentsDictionary
) {
  const type = getContentType(entry);

  const [Component, options = {}] = componentMap?.[type] || [];
  const { flattener, props: extraProps } = options;
  const fields = flattener ? flattener(entry) : entry;

  return { Component, props: { ...fields, ...extraProps }, type };
}

export function renderTextWithLineBreaks(text: string) {
  if (typeof text !== 'string') return '';
  return text
    .split('\n')
    .reduce<ReactNode[]>((children, textSegment, index) => {
      return [
        ...children,
        index > 0 && <br key={`line_break_${index}`} />,
        textSegment,
      ];
    }, []);
}
