export function getUpcomingDays(numberOfDays: number): Date[] {
  const today = new Date();
  const dates = [];

  for (let i = 0; i < numberOfDays; i++) {
    const currentDate = new Date();
    currentDate.setDate(today.getDate() + i);
    currentDate.setUTCHours(0);
    currentDate.setUTCMinutes(0);
    currentDate.setUTCSeconds(0);
    currentDate.setUTCMilliseconds(0);
    dates.push(currentDate);
  }

  return dates;
}
