import { ECallbackAgentType } from '../../mortgages.types';
import { EFieldName } from '../../create-callback-request/create-callback-request.types';
import { addHoursMinutesToDate } from '../helpers';

export function transformPurchaseData<T extends { [key in EFieldName]?: any }>(
  values: T,
  agentType: ECallbackAgentType
) {
  return {
    [EFieldName.FIRST_NAME]: values[EFieldName.FIRST_NAME],
    [EFieldName.LAST_NAME]: values[EFieldName.LAST_NAME],
    [EFieldName.PHONE_NUMBER]: values[EFieldName.PHONE_NUMBER],
    [EFieldName.EMAIL]: values[EFieldName.EMAIL],
    [EFieldName.CALL_BACK_WHEN]: addHoursMinutesToDate(
      values[EFieldName.CALL_ISO_DATE],
      values[EFieldName.CALL_TIME]
    ),
    [EFieldName.ACCEPT_TCS]: values[EFieldName.ACCEPT_TCS],
    [EFieldName.PURCHASE_INTENTION]: {
      [EFieldName.WHERE_ARE_YOU_AT]: values[EFieldName.WHERE_ARE_YOU_AT],
      [EFieldName.ANNUAL_INCOME]: values[EFieldName.ANNUAL_INCOME],
      [EFieldName.PROPERTY_VALUE]: values[EFieldName.PROPERTY_VALUE],
      [EFieldName.DEPOSIT]: values[EFieldName.DEPOSIT],
      [EFieldName.UK_CITIZEN]: values[EFieldName.UK_CITIZEN],
      [EFieldName.ANY_CCJS]: values[EFieldName.ANY_CCJS],
      [EFieldName.EXTRA_SUPPORT]: values[EFieldName.EXTRA_SUPPORT],
    },
    [EFieldName.CALL_BACK_AGENT_TYPE]: agentType,
    [EFieldName.CALL_DATE]: values[EFieldName.CALL_DATE],
  };
}
