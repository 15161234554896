import { useContext, useMemo } from 'react';

import { AnalyticsContext } from './analytics-provider';

export const useAnalytics = () => {
  const analyticsContext = useContext(AnalyticsContext);

  if (!analyticsContext) {
    throw new Error('useAnalytics must be used within an <AnalyticsProvider>');
  }

  return useMemo(() => analyticsContext?.analyticsTracker, [analyticsContext]);
};
