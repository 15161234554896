'use client';

import { useState } from 'react';

import { Text } from '@pbx/shared/ui/common/text';
import { Input } from '@pbx/shared/ui/common/form/input';
import { formatAsLocaleString } from '@pbx/shared/utilities/format-as-locale-string';
import { buildQueryString } from '@pbx/shared/utilities/build-query-string';

import { getResults } from './helpers/helpers';
import { variants } from './mortgage-calculator.variants';
import { MortgageCalculatorProps } from './mortgage-calculator.types';

export function MortgageCalculator({
  hasBackground = true,
  title,
  text,
  ctaText,
  ctaUrl,
  logo,
}: MortgageCalculatorProps) {
  const [inputState, setInputState] = useState({
    income: '',
    deposit: '',
  });

  const { propertyValue = '0' } = getResults(inputState);

  return (
    <div
      className={variants({ hasBackground })}
      data-testid="mortgage-calculator-container"
    >
      <div>
        {Boolean(logo) && (
          <img
            src={logo?.src}
            alt={logo?.alt}
            className="mb-8 max-w-[161px]"
            data-testid="mortgage-calculator-logo"
          />
        )}
        <Text
          variant="heading"
          size="large"
          as="h2"
          className="text-foreground-action-alt sm:mb-1 md:mb-3 lg:mb-3"
          data-testid="mortgage-calculator-title"
        >
          {title || 'Mortgage Calculator'}
        </Text>
        <Text
          as="p"
          variant="paragraph"
          size="large"
          className="text-foreground-secondary sm:mb-4 md:mb-6 lg:mb-6"
          data-testid="mortgage-calculator-description"
        >
          {text ||
            'Our mortgage calculator can compare mortgage deals that match your needs.'}
        </Text>
        <div className="grid gap-4 sm:mb-9 sm:grid-cols-1 md:mb-6 md:grid-cols-2 lg:mb-6 lg:grid-cols-2">
          <div>
            <Text
              as="h4"
              variant="heading"
              size="smallest"
              className="text-foreground-action mb-2"
              data-testid="mortgage-calculator-annual-income-label"
            >
              Annual income of applicants
            </Text>
            <Input
              type="text"
              onChange={(e) =>
                setInputState({
                  ...inputState,
                  income: (e.target.value ?? '').replace(/[\s,]/g, ''),
                })
              }
              value={formatAsLocaleString(inputState?.income ?? '')}
              className="border-border-contrast w-full focus:outline-none"
              icon="£"
              data-testid="mortgage-calculator-annual-income-input"
            />
          </div>
          <div>
            <Text
              as="h4"
              variant="heading"
              size="smallest"
              className="text-foreground-action mb-2"
              data-testid="mortgage-calculator-deposit-label"
            >
              Deposit
            </Text>
            <Input
              onChange={(e) =>
                setInputState({
                  ...inputState,
                  deposit: (e.target.value ?? '').replace(/[\s,]/g, ''),
                })
              }
              value={formatAsLocaleString(inputState?.deposit ?? '')}
              className="border-border-contrast w-full focus:outline-none"
              icon="£"
              data-testid="mortgage-calculator-deposit-input"
            />
          </div>
        </div>
        <div className="sm:mb-9 md:mb-6 lg:mb-6">
          <Text
            as="h4"
            variant="heading"
            size="smaller"
            className="text-foreground-action text-xl font-medium"
          >
            You could afford a property worth
          </Text>
          <Text
            as="h5"
            variant="heading"
            size="medium"
            className="text-foreground-primary"
            data-testid="mortgage-calculator-output"
          >{`£${propertyValue}`}</Text>
        </div>
        {Boolean(ctaUrl) && (
          <a
            href={ctaUrl + buildQueryString({ ...inputState, propertyValue })}
            className="bg-brand-button-primary-foreground text-background-primary text-md rounded-small flex w-full items-center justify-center py-3"
            data-testid="mortgage-calculator-cta"
          >
            {ctaText || 'Speak to a mortgage advisor'}
          </a>
        )}
      </div>
    </div>
  );
}
