import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';
import { buildQueryString } from '@pbx/shared/utilities/build-query-string';

import {
  TMortgageComparisonResults,
  TMortgageComparisonResultsQueryParams,
} from '../mortgage-comparison.types';
import { GET_MORTGAGE_COMPARISON_RESULTS } from '../mortgage-comparison.constants';

export async function getMortgageComparisonResults({
  propertyValue,
  deposit,
  mortgageType,
  termYears,
  pageNumber,
}: TMortgageComparisonResultsQueryParams) {
  const loanAmount = parseInt(propertyValue) - parseInt(deposit);
  const baseUrl = process.env.NEXT_PUBLIC_PB_BASE_API_URL;
  const queryString = buildQueryString({
    propertyValue,
    deposit,
    mortgageType: mortgageType as string,
    loanAmount: loanAmount,
    termYears: termYears,
    pageSize: '5',
    pageNumber: pageNumber,
  });
  return await fetchApi<TMortgageComparisonResults>(
    `${baseUrl}/mortgage-referrals-api/v1/mortgagecalculator${queryString}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetMortgageComparisonResults(
  {
    propertyValue,
    deposit,
    mortgageType,
    termYears,
    pageNumber,
    key,
  }: TMortgageComparisonResultsQueryParams,
  config: TUseQueryOptions<TMortgageComparisonResults> = {}
) {
  return useQuery({
    queryKey: [GET_MORTGAGE_COMPARISON_RESULTS, key],
    queryFn: () =>
      getMortgageComparisonResults({
        propertyValue,
        deposit,
        mortgageType,
        termYears,
        pageNumber,
      }),
    ...config,
  });
}
