import { Icon } from '@pbx/shared/ui/common/icon';
import { cn } from '@pbx/shared/utilities/combine-class-names';
import { RichText } from '@pbx/shared/ui/common/rich-text';

import { TFeatureListProps } from './feature-list.types';
import { iconVariants, listItemVariants } from './feature-list.variants';

export const FeatureList = ({
  items = [],
  appearance = 'default',
  listStyle = 'highlight',
  size = 'default',
  className,
}: TFeatureListProps) => {
  const iconWidth = size === 'default' ? 24 : 32;

  return (
    <ul className={cn('grid h-fit w-full gap-4 [&_p]:mb-0', className)}>
      {items.map((item, index) => (
        <li className="flex flex-row gap-4" key={`feature-list-item-${index}`}>
          <Icon
            name="tick-in-circle-solid"
            className={cn(iconVariants({ appearance, listStyle, size }))}
            width={iconWidth}
          />
          <RichText
            text={item}
            customClasses={{
              p: listItemVariants({ appearance, listStyle, size }),
            }}
            appearance={appearance}
            disableMargins
          />
        </li>
      ))}
    </ul>
  );
};
