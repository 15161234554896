import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { TLocationStatistics } from '../location.types';
import { GET_LOCATION_STATS } from '../location.constants';

export async function getLocationStats(postTown: string) {
  return await fetchApi<TLocationStatistics>(
    `${process.env.NEXT_PUBLIC_PB_BASE_API_URL}/statistic-api/v1/location/${postTown}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetLocationStats(
  postTown: string,
  config: TUseQueryOptions<TLocationStatistics> = {}
) {
  return useQuery({
    queryKey: [GET_LOCATION_STATS, postTown],
    queryFn: () => getLocationStats(postTown),
    ...config,
  });
}
