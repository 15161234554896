import React from 'react';

import { cn } from '@pbx/shared/utilities/combine-class-names';

import { ProgressCircleProps } from './progress-circle.types';

export const ProgressCircle = React.forwardRef<
  SVGSVGElement,
  ProgressCircleProps
>(
  (
    {
      value = 0,
      max = 100,
      radius = 100,
      strokeWidth = 48,
      showAnimation = true,
      className,
      children,
      ...props
    }: ProgressCircleProps,
    forwardedRef
  ) => {
    const safeValue = Math.min(max, Math.max(value, 0));
    const normalizedRadius = radius - strokeWidth / 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const offset = circumference - (safeValue / max) * circumference;

    return (
      <div className="relative h-fit w-fit">
        <svg
          ref={forwardedRef}
          width={radius * 2}
          height={radius * 2}
          viewBox={`0 0 ${radius * 2} ${radius * 2}`}
          className={cn('max-w-full -rotate-90 transform', className)}
          role="progressbar"
          aria-label="progress bar"
          aria-valuenow={value}
          aria-valuemin={0}
          aria-valuemax={max}
          data-max={max}
          data-value={safeValue ?? null}
          {...props}
        >
          <circle
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            strokeWidth={strokeWidth}
            fill="transparent"
            stroke=""
            className="stroke-background-tint transition-colors ease-linear"
          />
          {safeValue >= 0 ? (
            <circle
              r={normalizedRadius}
              cx={radius}
              cy={radius}
              strokeWidth={strokeWidth}
              strokeDasharray={`${circumference} ${circumference}`}
              strokeDashoffset={offset}
              fill="transparent"
              stroke=""
              className={cn(
                'stroke-background-action transition-colors ease-linear',
                showAnimation &&
                  'transform-gpu transition-all duration-300 ease-in-out'
              )}
            />
          ) : null}
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          {children}
        </div>
      </div>
    );
  }
);
