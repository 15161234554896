import { cva } from 'class-variance-authority';

export const variants = cva('', {
  variants: {
    appearance: {
      primary: 'bg-background-primary',
      secondary: 'bg-background-secondary sm:py-8 md:py-12 lg:py-16 ',
    },
  },
});
