import { format } from 'date-fns/format';
import { FocusEvent } from 'react';

import {
  ECallbackAgentType,
  ECallbackAvailability,
  EFieldName,
  EWhatDoYouNeed,
  EWhenDoYouNeedRemortgage,
  EWhereYouAt,
} from '@pbx/shared/api-client/services/mortgages';
import { addTimezoneOffsetToTime } from '@pbx/shared/utilities/add-timezoneoffset-to-time';
import { getTimezoneOffset } from '@pbx/shared/utilities/get-timezone-offset';
import { calculateLoanToValue } from '@pbx/shared/utilities/calculate-loan-to-value';
import { getDayDifference } from '@pbx/shared/utilities/get-day-difference';

import { TSubmitTrackingValues } from './lead-capture-form.types';
import {
  BEST_CALL_DAY_TEXT,
  RADIO_FIELD_LABELS,
} from './lead-capture-form.constants';

export function getCallDateOptions(
  callDates: string[],
  onDatePickerOpenChange: (isOpen: boolean) => void,
  lastAvailableDate?: Date
) {
  return [
    { value: '0', label: 'Today' },
    { value: '1', label: 'Tomorrow' },
    {
      value: '2',
      label: format(callDates[2], 'E, do'),
    },
    {
      value: '3',
      label: 'More dates',
      isDatePicker: true,
      onDatePickerOpenChange: onDatePickerOpenChange,
      disabledDates: {
        before: new Date(),
        after: lastAvailableDate,
      },
    },
  ];
}

export function getCallbackAvailabilityType({
  isPurchase,
  isReMortgage,
  purchaseStep,
}: {
  isPurchase: boolean;
  isReMortgage: boolean;
  purchaseStep?: EWhereYouAt;
}) {
  if (isReMortgage)
    return ECallbackAvailability.REMORTGAGE_ADVISOR_AVAILABILITY;

  if (isPurchase) {
    if (!purchaseStep) return;

    const isHotLead = getIsHotLead(purchaseStep);

    return isHotLead
      ? ECallbackAvailability.MORTGAGE_ADVISOR_AVAILABILITY
      : ECallbackAvailability.HELPLINE_AVAILABILITY;
  }
}

export function getCallbackAgentType({
  isPurchase,
  isReMortgage,
  purchaseStep,
}: {
  isPurchase?: boolean;
  isReMortgage?: boolean;
  purchaseStep?: EWhereYouAt;
}) {
  if (isReMortgage) return ECallbackAgentType.REMORTGAGE_ADVISOR;

  if (isPurchase) {
    if (!purchaseStep) return;

    const isHotLead = getIsHotLead(purchaseStep);

    return isHotLead
      ? ECallbackAgentType.MORTGAGE_ADVISOR
      : ECallbackAgentType.HELPLINE;
  }

  return;
}

export function getIsHotLead(purchaseStep: EWhereYouAt) {
  return [
    EWhereYouAt.READY_TO_OFFER,
    EWhereYouAt.MADE_ON_OFFER,
    EWhereYouAt.OFFER_ACCEPTED,
  ].includes(purchaseStep);
}

export function createSubmitTrackingDataObject(
  values: TSubmitTrackingValues,
  leadId?: string
) {
  const isPurchase =
    values[EFieldName.WHAT_DO_YOU_NEED] === EWhatDoYouNeed.PURCHASE;
  const intent = isPurchase ? 'purchase' : 'remortgage';
  const [callHours, callMinutes] = addTimezoneOffsetToTime(
    values[EFieldName.CALL_TIME],
    getTimezoneOffset()
  ).split(':');
  const today = format(new Date(), 'yyyy-MM-dd');
  const callDate = format(
    new Date(values[EFieldName.CALL_ISO_DATE]),
    'yyyy-MM-dd'
  );

  return {
    lead_type: 'mortgage',
    form_name: 'EA mortgage page',
    intent,
    progression:
      RADIO_FIELD_LABELS[values[EFieldName.WHERE_ARE_YOU_AT] as EWhereYouAt],
    annual_income: values[EFieldName.ANNUAL_INCOME],
    property_value: values[EFieldName.PROPERTY_VALUE],
    deposit: values[EFieldName.DEPOSIT],
    ltv: isPurchase
      ? calculateLoanToValue(
          values[EFieldName.PROPERTY_VALUE],
          values[EFieldName.DEPOSIT]
        ).toString()
      : undefined,
    time_to_remortgage:
      RADIO_FIELD_LABELS[
        values[
          EFieldName.WHEN_DO_YOU_NEED_REMORTGAGE
        ] as EWhenDoYouNeedRemortgage
      ],
    best_call_day_text: BEST_CALL_DAY_TEXT[values[EFieldName.CALL_DATE]],
    days_to_best_call_day: getDayDifference(
      new Date(today),
      new Date(callDate)
    ),
    best_call_time: `${callHours}:${callMinutes}`,
    lead_id: leadId,
  };
}

export function getFieldName(event: FocusEvent<HTMLFormElement>) {
  const hiddenInput = event.target.nextSibling as HTMLFormElement | null;

  return event.target.name || hiddenInput?.name;
}
