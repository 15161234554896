import React from 'react';

import { Text, textVariants } from '@pbx/shared/ui/common/text';
import { EShapeVariants, ShapeHouse } from '@pbx/shared/ui/shapes/shape-house';
import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Image } from '@pbx/shared/ui/common/image';

export function SubmissionSuccess() {
  const imgixUrl = process.env.NEXT_PUBLIC_PB_IMGIX_URL;

  return (
    <div className="bg-background-primary lg:p-13 flex h-full flex-col items-center sm:p-6 md:p-6">
      <div className="flex max-w-[560px] flex-col items-center gap-8">
        <ShapeHouse shape={EShapeVariants.TWO}>
          <Image
            src={`${imgixUrl}/web-images/mortgages/images/mortgages_lead_capture.png`}
            alt="submission success image"
            width={560}
            height={560 * (9 / 16)}
          />
        </ShapeHouse>
        <Text variant="heading" size="medium">
          Thanks! Here’s what happens next
        </Text>
        <ul
          className={cn(
            textVariants({ variant: 'paragraph', size: 'medium' }),
            'ml-4'
          )}
        >
          <li className="list-disc">We'll give you a call within 24 hours.</li>
          <li className="list-disc">The call will last around 15 minutes.</li>
          <li className="list-disc">
            We’ll understand what you’re looking for in a mortgage.
          </li>
          <li className="list-disc">
            If we can help, we’ll book an appointment with one of our mortgage
            advisors who will find the best rates for you.
          </li>
        </ul>
      </div>
    </div>
  );
}
