export function createAnalyticsService<T>(
  createFn: (args?: unknown) => T,
  createArguments: unknown[] = []
) {
  let analyticsTracker: T;

  return () => {
    if (analyticsTracker) {
      return analyticsTracker;
    }

    analyticsTracker = createFn(...createArguments);

    return analyticsTracker;
  };
}
