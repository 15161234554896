import { fetchApi, TUseMutationOptions } from '@pbx/shared/api-client/api-core';
import { useMutation } from '@pbx/shared/api-client/hooks-core';

import type { TMutationRequestBody } from './create-callback-request.types';

export async function createCallbackRequest(
  body: TMutationRequestBody
): Promise<unknown> {
  return await fetchApi(
    `${process.env.NEXT_PUBLIC_PB_BASE_API_URL}/mortgage-referrals-api/v1/selfserveformreferral`,
    {
      method: 'POST',
      body,
      mode: 'cors',
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    }
  );
}

export function useCreateCallbackRequest(
  config: TUseMutationOptions<unknown, Error, TMutationRequestBody> = {}
) {
  return useMutation<unknown, TMutationRequestBody, Error>({
    mutationFn: (variables: TMutationRequestBody) =>
      createCallbackRequest(variables),
    ...config,
  });
}
