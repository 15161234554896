export {
  getLocationStats,
  useGetLocationStats,
} from './lib/get-location-stats/get-location-stats';
export {
  getLocations,
  useGetLocations,
} from './lib/get-locations/get-locations';
export { GET_LOCATION_STATS } from './lib/location.constants';
export type { TLocationStatistics, TLocation } from './lib/location.types';
