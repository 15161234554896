export function formatAsLocaleString(value?: string) {
  let parsedValue = parseFloat((value || '').replace(/[\s,]/g, ''));

  if (isNaN(parsedValue)) {
    parsedValue = 0;
  }

  return parsedValue.toLocaleString('en-GB', {
    minimumFractionDigits: 0,
  });
}
