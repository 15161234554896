'use client';

import { StampDutyCalculator } from '@pbx/shared/ui/tools/stamp-duty-calculator';
import { Image } from '@pbx/shared/ui/common/image';

import { TStampDutyCalculatorBlockProps } from './stamp-duty-calculator-block.types';

export function StampDutyCalculatorBlock({
  cta,
  image,
  title,
  text,
  logo,
}: TStampDutyCalculatorBlockProps) {
  return (
    <div data-testid="container" className="md:container lg:container">
      <div className="bg-background-secondary md:rounded-large lg:rounded-large m-0 grid w-full gap-20 sm:grid-cols-1 sm:px-4 sm:py-9 md:grid-cols-1 md:p-8 lg:grid-cols-2 lg:p-16">
        <StampDutyCalculator
          title={title}
          text={text}
          ctaText={cta?.label}
          ctaUrl={cta?.link?.href}
          logo={logo}
          hasBackground={false}
        />
        <Image
          src={image.src}
          width={image.width}
          height={image.height}
          alt={image.alt}
          className="h-full w-full object-cover sm:hidden md:hidden lg:block"
        />
      </div>
      <Image
        src={image.src}
        width={image.width}
        height={image.height}
        alt={image.alt}
        className="w-full sm:block md:hidden lg:hidden"
      />
    </div>
  );
}
