'use client';
import { useMediaSize } from '@pbx/shared/utilities/hooks/use-media-size';

import { TEmbeddedComponentBlockProps } from './embedded-component-block.types';
import { getHeightByMediaSize } from './helpers/get-height-by-media-size/get-height-by-media-size';
import { convertCssStringToObject } from './helpers/convert-css-string-to-object/convert-css-string-to-object';

export function EmbeddedComponentBlock({
  iFrameAttributes,
  title,
  heightMobile = '500',
  heightTablet = '500',
  heightDesktop = '500',
}: TEmbeddedComponentBlockProps) {
  const { isSm, isMd } = useMediaSize();

  const height = getHeightByMediaSize({
    isSm,
    isMd,
    heightMobile,
    heightTablet,
    heightDesktop,
  });

  return (
    <div className="container flex justify-center">
      <iframe
        width="100%"
        height={height}
        title={title}
        {...iFrameAttributes}
        style={convertCssStringToObject(iFrameAttributes?.style)}
      />
    </div>
  );
}
