import React, { PropsWithChildren } from 'react';

import { textVariants } from '@pbx/shared/ui/common/text';
import { cn } from '@pbx/shared/utilities/combine-class-names';

import { THTMLProps } from './blockquote.types';

export function Blockquote({
  children,
  className,
}: PropsWithChildren<THTMLProps>) {
  return (
    <blockquote
      className={cn(
        textVariants({ variant: 'display', size: 'small' }),
        'text-foreground-highlight flex gap-4',
        className
      )}
    >
      <span>&#8220;</span>
      <div>{children}</div>
    </blockquote>
  );
}
