import { cva } from 'class-variance-authority';

import { textVariants } from '@pbx/shared/ui/common/text';

export const variants = cva('', {
  variants: {
    variant: {
      paragraph: `${textVariants({ variant: 'paragraph', size: 'medium' })} mb-4`,
      h1: `${textVariants({ variant: 'heading', size: 'large' })} mb-3`,
      h2: `${textVariants({ variant: 'heading', size: 'large' })} mb-3`,
      h3: `${textVariants({ variant: 'heading', size: 'small' })} text-foreground-highlight mb-3`,
      h4: `${textVariants({ variant: 'heading', size: 'smaller' })} mb-3`,
      h5: `${textVariants({ variant: 'heading', size: 'smaller' })} mb-3`,
      h6: `${textVariants({ variant: 'heading', size: 'smaller' })} mb-3`,
      li: `${textVariants({ variant: 'paragraph', size: 'medium-tight' })} mb-3`,
      ul: 'pl-4 mb-4 list-[square] text-foreground-highlight [&>li>p]:text-foreground-primary',
      ol: 'pl-4 mb-4 list-decimal',
      a: 'underline',
      img: 'my-8',
      quote: 'my-8',
      table: 'w-full',
      'table-row':
        'border-b border-b-[black] border-opacity-10 [&:last-child]:border-b-0',
      'table-cell': `p-4 ${textVariants({ variant: 'paragraph', size: 'small-tight' })} [&_p]:mb-2`,
      'table-header-cell': `border-b border-b-border-contrast p-4 text-start [&_p]:m-0 ${textVariants({ variant: 'paragraph', size: 'small-tight' })}`,
    },
    type: {
      heading: `mt-8 [&+h1]:mt-3 [&+h2]:mt-3 [&+h3]:mt-3 [&+h4]:mt-3 [&+h5]:mt-3 [&+h6]:mt-3`,
      paragraph: '',
    },
    appearance: {
      default: '',
      brand: '',
      mortgagePrimary: 'text-background-secondary',
      mortgageSecondary: 'text-foreground-secondary',
    },
  },
  compoundVariants: [
    {
      appearance: 'default',
      variant: 'a',
      class: 'text-foreground-action',
    },
    {
      appearance: 'brand',
      variant: 'a',
      class: 'text-brand-foreground-action',
    },
  ],
});

export const richTextBlockVariants = cva('', {
  variants: {
    disableMargins: {
      true: '[&>*:first-child]:mt-0 [&>*:last-child]:mb-0 [&>.icon-container+h3]:mt-0',
      false: '',
    },
  },
});
