import React from 'react';

export function PleaseReadMessage() {
  return (
    <div className="bg-background-secondary px-4 py-3 text-left">
      Please read the Purplebricks Mortgages Limited{' '}
      <a href="/privacy-policy" className="underline">
        privacy policy
      </a>{' '}
      and{' '}
      <a href="/terms-and-conditions" className="underline">
        terms and conditions
      </a>{' '}
      before proceeding.
    </div>
  );
}
