import React from 'react';

import { Icon } from '@pbx/shared/ui/common/icon';
import { Text } from '@pbx/shared/ui/common/text';
import { FeatureList } from '@pbx/shared/ui/common/feature-list';
import { Button } from '@pbx/shared/ui/common/button';
import { Link } from '@pbx/shared/ui/common/link';
import { cn } from '@pbx/shared/utilities/combine-class-names';

import { TServiceCardProps } from './service-card.types';
import {
  highlightTextVariants,
  serviceCardVariants,
} from './service-card.variants';

export function ServiceCard({
  title,
  state,
  tag,
  icon,
  subtitle,
  description,
  featureList,
  preListText,
  postListText,
  button,
  className,
}: TServiceCardProps) {
  return (
    <div className={cn('relative flex', className)}>
      <div className={serviceCardVariants({ state })}>
        {tag && (
          <div className="bg-background-active rounded-t-large text-foreground-contrast absolute left-0 top-0 w-full px-8 py-1 text-center uppercase">
            {tag}
          </div>
        )}
        <div className="flex flex-col gap-4">
          {icon && (
            <Icon
              name={icon}
              width={48}
              height={48}
              strokeWidth={1.5}
              className={highlightTextVariants({ state })}
            />
          )}
          <div className="flex flex-col gap-2">
            <Text
              as="h2"
              variant="heading"
              size="medium"
              className="text-foreground-primary"
            >
              {title}
            </Text>
            {subtitle && (
              <Text
                as="h3"
                variant="heading"
                size="small"
                className={highlightTextVariants({ state })}
              >
                {subtitle}
              </Text>
            )}
            {description && (
              <Text
                as="p"
                variant="paragraph"
                size="medium"
                className="text-foreground-secondary"
              >
                {description}
              </Text>
            )}
          </div>
          {featureList && (
            <div className="border-t-border-visual flex flex-col gap-6 border-t pt-4">
              {preListText && (
                <Text
                  as="h4"
                  variant="heading"
                  size="smaller"
                  className="text-foreground-highlight"
                >
                  {preListText}
                </Text>
              )}

              <FeatureList {...featureList} />
              {postListText && (
                <Text
                  as="p"
                  variant="paragraph"
                  size="medium"
                  className="text-foreground-primary"
                >
                  {postListText}
                </Text>
              )}
            </div>
          )}
        </div>
        {button?.link?.href && (
          <Button variant="primary" asChild>
            <Link href={button.link.href || ''} newTab={button.newTab}>
              {button.label}
            </Link>
          </Button>
        )}
      </div>
    </div>
  );
}
