import { parseISO } from 'date-fns/parseISO';
import { setHours } from 'date-fns/setHours';
import { setMinutes } from 'date-fns/setMinutes';
import { setSeconds } from 'date-fns/setSeconds';
import { formatISO } from 'date-fns/formatISO';

import { ECallbackAgentType } from '../mortgages.types';
import { EWhereYouAt } from '../create-callback-request/create-callback-request.types';

export function addHoursMinutesToDate(
  isoDate: string | undefined,
  time: string
) {
  if (!isoDate) return;

  let date = parseISO(isoDate);
  const [hours, minutes] = time.split(':');

  date = setHours(date, Number(hours));
  date = setMinutes(date, Number(minutes));
  date = setSeconds(date, 0);

  return formatISO(date);
}

export function getCallbackAgentType({
  isPurchase,
  isReMortgage,
  purchaseStep,
  isHotLead = true,
}: {
  isPurchase?: boolean;
  isReMortgage?: boolean;
  purchaseStep?: EWhereYouAt;
  isHotLead?: boolean;
}) {
  if (isReMortgage) return ECallbackAgentType.REMORTGAGE_ADVISOR;

  if (isPurchase) {
    if (!purchaseStep) return;

    return isHotLead
      ? ECallbackAgentType.MORTGAGE_ADVISOR
      : ECallbackAgentType.HELPLINE;
  }

  return;
}
