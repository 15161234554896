'use client';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Text } from '@pbx/shared/ui/common/text';
import { Input } from '@pbx/shared/ui/common/form/input';
import { Icon } from '@pbx/shared/ui/common/icon';
import { FeatureList } from '@pbx/shared/ui/common/feature-list';
import { Button } from '@pbx/shared/ui/common/button';
import { Loading } from '@pbx/shared/ui/common/loading';
import { Form, FormField } from '@pbx/shared/ui/common/form/form';
import { Error } from '@pbx/shared/ui/common/form/error';
import { useAnalytics } from '@pbx/shared/analytics';
import { POSTCODE_REGEX } from '@pbx/shared/utilities/validation/regex';

import { PostCodeNotFound } from './postcode-not-found/post-code-not-found';
import { Results } from './results/results';
import { LocalExperts } from './local-experts/local-experts';
import { ResultsAddOns } from './results-add-ons/results-add-ons';
import { useGetFeesAndStats } from './use-get-fees-and-stats';

const validationSchema = z.object({
  postCode: z.string().regex(POSTCODE_REGEX, {
    message: 'A valid postcode needs to be entered',
  }),
});

export function FeeCheckerBlock() {
  const analytics = useAnalytics();
  const form = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      postCode: '',
    },
  });
  const postCode = form.getValues('postCode');
  const postCodeInvalid = form.getFieldState('postCode').invalid;

  const {
    packagesCollection,
    getFeesAndStats,
    isLoadingFeesAndStats,
    totalPropertiesSold,
    localExperts,
    postTown,
    postcodeNotFound,
    hasError,
  } = useGetFeesAndStats(postCode);

  useEffect(() => {
    if (postCodeInvalid) {
      analytics.trackGAEvent('feeChecker', {
        response: 'invalid postcode',
        journey: 'sell',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCodeInvalid, postCode]);

  const getContent = () => {
    if (isLoadingFeesAndStats) {
      return (
        <div className="min-w-full sm:min-h-[512px] md:min-h-[360px] lg:min-h-[352px]">
          <Loading />
        </div>
      );
    }

    if (postcodeNotFound) {
      return <PostCodeNotFound />;
    }

    if (packagesCollection?.length) {
      return (
        <div className="flex flex-col gap-6">
          <Results
            postCode={postCode || ''}
            packagesCollection={packagesCollection}
          />
          <div className="flex sm:flex-col sm:gap-4 md:flex-col md:gap-8 lg:gap-8">
            <ResultsAddOns />
            {!!localExperts?.length && totalPropertiesSold ? (
              <LocalExperts
                localExperts={localExperts}
                postTown={postTown}
                allTimeSales={totalPropertiesSold}
              />
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div className="flex w-full flex-col sm:gap-4 md:gap-8 lg:max-w-[620px] lg:gap-8">
        <Text
          size="large"
          variant="heading"
          className="text-foreground-highlight"
          as="h2"
        >
          Ready to sell for free?
        </Text>
        <div>
          <FeatureList
            items={[
              'Save £1000s on your home move',
              'Expert estate agents at every step',
              'Pay only for the services you need',
              'Manage your move on the app',
            ]}
            listStyle="positive"
            className="sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2"
          />
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(() => getFeesAndStats())}>
            <div className="flex flex-col items-start gap-2 sm:flex-col md:flex-row lg:flex-row">
              <div className="w-full">
                <FormField control={form.control} name="postCode">
                  <Input
                    label="Enter your postcode to find out more"
                    placeholder=" E.g. B90 4RZ"
                    icon={<Icon name={'search'} />}
                    className="w-full"
                  />
                </FormField>
                {hasError && (
                  <Error className="mt-3">
                    Something went wrong, please try again
                  </Error>
                )}
              </div>
              <Button
                type="submit"
                variant="primary"
                className="text-nowrap sm:w-full md:mt-[32px] lg:mt-[32px]"
              >
                Get started
              </Button>
            </div>
          </form>
        </Form>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="bg-background-tertiary rounded-large flex p-4 md:p-8 lg:justify-center lg:px-24 lg:py-16">
        {getContent()}
      </div>
    </div>
  );
}
