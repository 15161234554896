import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ReactNode } from 'react';

import { cn } from '@pbx/shared/utilities/combine-class-names';

import { getOptions } from './rich-text.helpers';
import { TRichTextProps, TText } from './rich-text.types';
import { richTextBlockVariants } from './rich-text.variants';

export function RichText({
  text,
  className,
  disableMargins = false,
  customClasses,
  appearance = 'default',
  componentMap,
}: TRichTextProps) {
  const richTextOptions = getOptions({
    customClasses,
    appearance,
    componentMap,
  });
  const elements = (text as TText)?.nodeType ? (
    documentToReactComponents(text as TText, richTextOptions)
  ) : (
    <p className={customClasses?.p}>{text as ReactNode}</p>
  );

  return (
    <div className={cn(richTextBlockVariants({ disableMargins }), className)}>
      {elements}
    </div>
  );
}
