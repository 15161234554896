import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { TSliderProps } from './slider.types';
import { rangeVariants, thumbVariants } from './slider.variants';

export const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & TSliderProps
>(
  (
    {
      variant,
      defaultValue,
      min,
      max,
      step,
      id,
      disabled,
      onChange,
      onValueCommit,
      ...props
    },
    ref
  ) => (
    <form id={id}>
      <SliderPrimitive.Root
        ref={ref}
        className="relative flex h-5 touch-none select-none items-center py-4"
        defaultValue={defaultValue}
        min={min}
        max={max}
        step={step}
        onValueChange={onChange}
        onValueCommit={onValueCommit}
        disabled={disabled}
        {...props}
      >
        <SliderPrimitive.Track className="bg-brand-foreground-disabled rounded-large relative h-[4px] grow">
          <SliderPrimitive.Range className={rangeVariants({ variant })} />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
          className={thumbVariants({ variant })}
          aria-label="Volume"
        />
      </SliderPrimitive.Root>
    </form>
  )
);
