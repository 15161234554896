export function getResults({
  income,
  deposit,
}: {
  income: string;
  deposit: string;
}) {
  let parsedIncome = parseFloat(income);
  let parsedDeposit = parseFloat(deposit);

  if (isNaN(parsedIncome)) {
    parsedIncome = 0;
  }

  if (isNaN(parsedDeposit)) {
    parsedDeposit = 0;
  }

  const borrowing = parsedIncome * 4.75;
  const propertyValue = borrowing + parsedDeposit;

  return {
    borrowing: borrowing.toLocaleString('en-GB', {
      minimumFractionDigits: 0,
    }),
    propertyValue: propertyValue.toLocaleString('en-GB', {
      minimumFractionDigits: 0,
    }),
  };
}
