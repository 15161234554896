import { useCallback, useEffect } from 'react';

import {
  EFieldName,
  EWhatDoYouNeed,
} from '@pbx/shared/api-client/services/mortgages';

import { TUseLeadCaptureFormProps } from './use-lead-capture-form.types';
import {
  getCallbackAgentType,
  getCallbackAvailabilityType,
} from '../lead-capture-form.helpers';

export function useLeadCaptureForm({
  form,
  fetchAppointments,
  isLoading = false,
}: TUseLeadCaptureFormProps) {
  const { resetField, watch, formState } = form;
  const { isSubmitSuccessful, isDirty } = formState;

  const {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    acceptedTerms,
    whatDoYouNeed,
    callTime,
    callDate,
  } = watch();

  const personalDetailsCompleted =
    firstName && lastName && phoneNumber && emailAddress;

  const isPurchase = !!(
    personalDetailsCompleted && whatDoYouNeed === EWhatDoYouNeed.PURCHASE
  );
  const isReMortgage = !!(
    personalDetailsCompleted && whatDoYouNeed === EWhatDoYouNeed.RE_MORTGAGE
  );

  const whenDoYouNeedRemortgage = isReMortgage
    ? watch(EFieldName.WHEN_DO_YOU_NEED_REMORTGAGE)
    : undefined;

  const doYouNeedExtraSupport = isPurchase
    ? watch(EFieldName.EXTRA_SUPPORT)
    : undefined;
  const whereAreYouAt = isPurchase
    ? watch(EFieldName.WHERE_ARE_YOU_AT)
    : undefined;
  const areYouUkCitizen = isPurchase ? watch(EFieldName.UK_CITIZEN) : undefined;
  const annualIncome = isPurchase ? watch(EFieldName.ANNUAL_INCOME) : undefined;
  const propertyValue = isPurchase
    ? (watch(EFieldName.PROPERTY_VALUE) as number)
    : undefined;
  const deposit = isPurchase
    ? (watch(EFieldName.DEPOSIT) as number)
    : undefined;
  const haveYouHadAnyCCJs = isPurchase ? watch(EFieldName.ANY_CCJS) : undefined;

  const showTCs =
    (!!doYouNeedExtraSupport || !!whenDoYouNeedRemortgage) && !!callTime;

  const showCalendar = !!doYouNeedExtraSupport || !!whenDoYouNeedRemortgage;
  const isSubmitButtonDisabled =
    !acceptedTerms || !callTime || isLoading || isSubmitSuccessful;

  const showSecondStep = !!whereAreYouAt;
  const showThirdStep = showSecondStep && !!annualIncome;
  const showFourthStep = showThirdStep && !!(propertyValue && deposit);
  const showFifthStep = showFourthStep && !!areYouUkCitizen;
  const showSixthStep = showFifthStep && !!haveYouHadAnyCCJs;

  const resetCalendarFields = useCallback(() => {
    resetField(EFieldName.CALL_DATE);
    resetField(EFieldName.CALL_TIME);
  }, [resetField]);

  const resetPurchaseJourneyFields = useCallback(() => {
    resetField(EFieldName.WHERE_ARE_YOU_AT);
    resetField(EFieldName.ANNUAL_INCOME);
    resetField(EFieldName.PROPERTY_VALUE);
    resetField(EFieldName.DEPOSIT);
    resetField(EFieldName.UK_CITIZEN);
    resetField(EFieldName.ANY_CCJS);
    resetField(EFieldName.EXTRA_SUPPORT);
    resetCalendarFields();
    resetField(EFieldName.ACCEPT_TCS);
  }, [resetCalendarFields, resetField]);

  const resetReMortgageJourneyFields = useCallback(() => {
    resetField(EFieldName.WHEN_DO_YOU_NEED_REMORTGAGE);
    resetCalendarFields();
    resetField(EFieldName.ACCEPT_TCS);
  }, [resetCalendarFields, resetField]);

  const callbackAvailability = getCallbackAvailabilityType({
    isPurchase,
    isReMortgage,
    purchaseStep: whereAreYouAt,
  });

  useEffect(() => {
    if (isPurchase) resetReMortgageJourneyFields();
  }, [isPurchase, resetReMortgageJourneyFields]);

  useEffect(() => {
    if (isReMortgage) resetPurchaseJourneyFields();
  }, [isReMortgage, resetPurchaseJourneyFields]);

  useEffect(() => {
    if (!callDate) resetField(EFieldName.CALL_TIME);
  }, [callDate, resetField]);

  useEffect(() => {
    if (showCalendar) {
      const agentType = getCallbackAgentType({
        isPurchase,
        isReMortgage,
        purchaseStep: whereAreYouAt,
      });
      if (agentType) fetchAppointments?.(agentType);
    }
  }, [
    fetchAppointments,
    isPurchase,
    isReMortgage,
    showCalendar,
    whereAreYouAt,
  ]);

  return {
    callbackAvailability,
    callDate,
    deposit,
    isPurchase,
    isReMortgage,
    isSubmitButtonDisabled: isSubmitButtonDisabled,
    isDirty,
    propertyValue,
    showCalendar,
    showTCs,
    showWhatDoYouNeedSelector: personalDetailsCompleted,
    purchaseJourney: {
      showSecondStep,
      showThirdStep,
      showFourthStep,
      showFifthStep,
      showSixthStep,
    },
    whereAreYouAt,
  };
}
