import { cn } from '@pbx/shared/utilities/combine-class-names';
import { textVariants } from '@pbx/shared/ui/common/text';

import { TAccordionProps } from './accordion.types';
import { AccordionRoot } from './accordion-root/accordion-root';
import { AccordionItem } from './accordion-item/accordion-item';
import { AccordionTrigger } from './accoridion-trigger/accordion-trigger';
import { AccordionContent } from './accordion-content/accordion-content';

export function Accordion({ items, type = 'single' }: TAccordionProps) {
  return (
    <AccordionRoot type={type} className="w-full">
      {items.map((item, index) => (
        <AccordionItem
          key={`accordion-item-${index + 1}`}
          value={`item-${index + 1}`}
        >
          <AccordionTrigger
            className={cn(
              textVariants({ variant: 'heading', size: 'smaller' }),
              'text-foreground-action '
            )}
          >
            {item.title}
          </AccordionTrigger>
          <AccordionContent
            className={textVariants({ variant: 'paragraph', size: 'medium' })}
          >
            {item.content}
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionRoot>
  );
}
