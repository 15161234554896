export const screenTokens: Record<string, any> = {
  sm: { min: '0px', max: '599px' },
  // => @media (min-width: 320px and max-width: 599px) { ... }

  md: { min: '600px', max: '1151px' },
  // => @media (min-width: 600px and max-width: 1151px) { ... }

  lg: { min: '1152px' },
  // => @media (min-width: 1152px) { ... }
};
