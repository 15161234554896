'use client';

import { Text } from '@pbx/shared/ui/common/text';
import {
  EServiceCardState,
  ServiceCard,
} from '@pbx/shared/ui/common/service-card';
import { formatNumber } from '@pbx/shared/utilities/format-number';

import { TPostCodeWithDataProperties } from './results.types';

export function Results({
  postCode,
  packagesCollection,
}: TPostCodeWithDataProperties) {
  const BOOST_PACKAGE_ID = parseInt(
    process.env.NEXT_PUBLIC_PACKAGE_BOOST_ID || '168',
    10
  );
  const FULL_HOUSE_PACKAGE_ID = parseInt(
    process.env.NEXT_PUBLIC_PACKAGE_FULL_HOUSE_ID || '169',
    10
  );

  function getPriceForPackageById(id: number) {
    const packageData = packagesCollection?.find(
      (packageItem) => packageItem.id === id
    );

    return `£${formatNumber(packageData?.calculated_price)}`;
  }

  function isPostcodeVirtual() {
    return !packagesCollection?.find(
      (packageItem) => packageItem.id === FULL_HOUSE_PACKAGE_ID
    );
  }

  return (
    <div className="flex flex-col sm:gap-4 md:gap-6 lg:gap-8">
      <div className="flex flex-col gap-1">
        <Text
          variant="heading"
          size="large"
          className="text-foreground-highlight"
          as="h2"
        >
          You can sell for free in {postCode}
        </Text>
        <Text variant="heading" size="smaller" as="h3">
          Or upgrade to a package
        </Text>
      </div>
      <div className="flex sm:flex-col sm:gap-4 md:grid md:grid-cols-2 md:gap-4 lg:flex-row lg:gap-8">
        <div className="bg-background-primary rounded-large flex min-h-fit flex-1 md:col-span-2 md:w-full">
          <ServiceCard
            title="Sell for free"
            state={EServiceCardState.DEFAULT}
            subtitle="From £0"
            icon="free"
            description="Everything you need to sell your home. And we won’t charge you a penny."
            className="md:w-full"
          />
        </div>
        <div className="bg-background-primary rounded-large flex min-h-fit flex-1">
          <ServiceCard
            title="Boost*"
            state={EServiceCardState.DEFAULT}
            subtitle={getPriceForPackageById(BOOST_PACKAGE_ID)}
            icon="boost"
            description="Boost your sale with pro photos, a virtual tour and Premium Rightmove listing."
          />
        </div>
        {isPostcodeVirtual() ? (
          <div className="bg-background-tint rounded-large flex min-h-fit flex-1">
            <ServiceCard
              title="Full House*"
              state={EServiceCardState.DISABLED}
              icon="full-house"
              description="Sorry, this package is not currently available in your area. You can still sell with us for free, upgrade to our Boost package and get a virtual valuation with one of our local experts."
            />
          </div>
        ) : (
          <div className="bg-background-primary rounded-large flex min-h-fit flex-1">
            <ServiceCard
              title="Full House*"
              state={EServiceCardState.DEFAULT}
              subtitle={getPriceForPackageById(FULL_HOUSE_PACKAGE_ID)}
              icon="full-house"
              description="Total support – includes everything in Free and Boost, plus hosted viewings."
            />
          </div>
        )}
      </div>
      <Text variant="paragraph" size="small-tight" as="p">
        * Separate £80 Anti-Money Laundering fee applies.
      </Text>
    </div>
  );
}
