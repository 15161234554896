import { useFormContext } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isValid } from 'date-fns/isValid';
import { parseISO } from 'date-fns/parseISO';
import { format } from 'date-fns/format';

import { getUpcomingDays } from '@pbx/shared/utilities/get-upcoming-days';
import { FormField } from '@pbx/shared/ui/common/form/form';
import { ToggleSelect } from '@pbx/shared/ui/common/form/toggle-select';
import { EFieldName } from '@pbx/shared/api-client/services/mortgages';
import { getTimezoneOffset } from '@pbx/shared/utilities/get-timezone-offset';
import { Loading } from '@pbx/shared/ui/common/loading';

import { TUseFormReturn } from '../lead-capture-form.types';
import { getCallDateOptions } from '../lead-capture-form.helpers';
import { TCalendarProps } from './calendar.types';
import {
  getAppointmentsByDate,
  getCallTimeOptions,
  getLastAvailableAppointment,
} from './calendar.helpers';

export function Calendar({
  callDate,
  callbackAppointments = [],
  isLoading = false,
}: TCalendarProps) {
  const { control, setValue } = useFormContext<TUseFormReturn>();
  const [selectedDate, setSelectedDate] = useState<string>('');

  const callDates = useMemo(
    () => getUpcomingDays(3).map((date) => date.toISOString()),
    []
  );
  const appointmentsByDate = useMemo(() => {
    return getAppointmentsByDate(callbackAppointments);
  }, [callbackAppointments]);

  const callTimeOptions = useMemo(() => {
    return getCallTimeOptions(
      appointmentsByDate,
      selectedDate,
      getTimezoneOffset()
    );
  }, [appointmentsByDate, selectedDate]);

  const lastAvailableDate = useMemo(() => {
    return getLastAvailableAppointment(callbackAppointments);
  }, [callbackAppointments]);

  const resetCallTimeValue = useCallback(() => {
    setValue(EFieldName.CALL_TIME, '');
  }, [setValue]);

  const setCallDateValue = useCallback(
    (value: string) => {
      // cast as never to bypass TS error due the union schema
      setValue(EFieldName.CALL_DATE, value as never);
    },
    [setValue]
  );

  const onDatePickerOpenChange = (isOpened: boolean) => {
    if (!isOpened && callDate === '3' && !selectedDate) {
      setCallDateValue('');
    }
  };

  useEffect(() => {
    resetCallTimeValue();
    const isCustomDateSelected = !!(
      selectedDate && !callDates.includes(selectedDate)
    );

    // keep "More dates" button selected if custom date is selected and datepicker closes
    if (callDate === '' && isCustomDateSelected) {
      setCallDateValue('3');
      return;
    }

    // clear selectedDate state if none of the options is selected, or datepicker opens up
    if (callDate === '' || (callDate === '3' && !isCustomDateSelected)) {
      setSelectedDate('');
      setCallDateValue('');
      return;
    }

    // set selectedDate state if one of the 3 predefined option selected
    if (['0', '1', '2'].includes(callDate)) {
      setSelectedDate(callDates[Number(callDate)]);
      return;
    }

    // set selectedDate state if custom date is selected via datepicker
    if (callDate && isValid(parseISO(callDate))) {
      setSelectedDate(callDate);
      setCallDateValue('3');
    }
  }, [
    callDate,
    callDates,
    resetCallTimeValue,
    selectedDate,
    setCallDateValue,
    setValue,
  ]);

  // update callIsoDate form field with the current selected date
  useEffect(() => {
    setValue(EFieldName.CALL_ISO_DATE, selectedDate);
  }, [selectedDate, setValue]);

  if (isLoading) return <Loading text="Getting appointments" />;

  return (
    <>
      <FormField control={control} name={EFieldName.CALL_DATE}>
        <ToggleSelect
          columns={[2, 4, 4]}
          label="When’s best for us to call?"
          type="single"
          className="w-full"
          options={getCallDateOptions(
            callDates,
            onDatePickerOpenChange,
            lastAvailableDate
          )}
        />
      </FormField>
      {!!selectedDate && isValid(parseISO(selectedDate)) && (
        <div className="bg-background-tertiary p-3 text-center">
          {format(parseISO(selectedDate), 'EEEE, do MMMM yyyy')}
        </div>
      )}
      {selectedDate && (
        <FormField control={control} name={EFieldName.CALL_TIME}>
          <ToggleSelect
            columns={[2, 5, 5]}
            label="Select a time"
            type="single"
            className="w-full"
            options={callTimeOptions}
          />
        </FormField>
      )}
    </>
  );
}
