import { cva } from 'class-variance-authority';

import { EServiceCardState } from './service-card.types';

export const serviceCardVariants = cva(
  'rounded-large group flex flex-col justify-between gap-6 border sm:pt-12 sm:px-4 sm:pb-6 md:pt-12 md:px-6 md:pb-6 lg:pt-12 lg:px-8 lg:pb-8 w-full',
  {
    variants: {
      state: {
        [EServiceCardState.DEFAULT]: 'border-border-visual',
        [EServiceCardState.HIGHLIGHTED]:
          'border-border-active bg-background-tertiary',
        [EServiceCardState.DISABLED]: 'border-border-visual bg-background-tint',
      },
    },
  }
);

export const highlightTextVariants = cva('', {
  variants: {
    state: {
      [EServiceCardState.DEFAULT]: 'text-foreground-highlight',
      [EServiceCardState.HIGHLIGHTED]: 'text-foreground-highlight',
      [EServiceCardState.DISABLED]: 'text-foreground-secondary',
    },
  },
});
