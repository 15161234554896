'use client';

import React from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';

import { Label } from '@pbx/shared/ui/common/form/label';
import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Grid } from '@pbx/shared/ui/layout/grid';
import { Error } from '@pbx/shared/ui/common/form/error';
import { DatePicker } from '@pbx/shared/ui/common/date-picker';

import { ToggleGroup, ToggleGroupItem } from './toggle-group';
import { TToggleSelectProps } from './toggle-select.types';
import { variants } from './toggle-select.variants';

export const ToggleSelect = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  TToggleSelectProps
>(
  (
    {
      options,
      onChange,
      label,
      helper,
      className,
      columns = [2, 2, 2],
      error,
      disabled,
      labelSize = 'default-500',
      maxWidth = 134,
      testId = 'toggle',
      name,
      ...props
    },
    _
  ) => {
    const getVariant = () => {
      if (disabled) return 'disabled';

      if (error) return 'error';

      return undefined;
    };

    return (
      <div className={cn('inline-flex flex-col', className)}>
        <Label className="mb-3" labelSize={labelSize} helper={helper}>
          {label}
        </Label>
        <ToggleGroup
          onValueChange={onChange}
          disabled={disabled}
          data-testid={testId}
          {...props}
        >
          <Grid gap={[4, 4, 4]} columns={columns}>
            {options.map((option, index) => {
              const variant = option.disabled ? 'disabled' : getVariant();

              const toggleItemComponent = (
                <ToggleGroupItem
                  data-testid={`${testId}-${option.value}-option`}
                  key={`toggle-item-${index}`}
                  value={option.value}
                  aria-label={option.ariaLabel || option.value}
                  className={cn(
                    variants({ variant }),
                    'w-full min-w-full whitespace-nowrap'
                  )}
                  style={{ maxWidth: maxWidth ? maxWidth : undefined }}
                  disabled={option.disabled}
                  name={name}
                >
                  {option.label}
                </ToggleGroupItem>
              );

              if (option.isDatePicker) {
                return (
                  <DatePicker
                    key={`toggle-item-datepicker-${index}`}
                    onChange={(value) =>
                      value && onChange && onChange(value.toISOString())
                    }
                    align="end"
                    asChild={true}
                    onOpenChange={option.onDatePickerOpenChange}
                    disabled={option.disabledDates}
                  >
                    {toggleItemComponent}
                  </DatePicker>
                );
              }

              return toggleItemComponent;
            })}
          </Grid>
        </ToggleGroup>
        {error && <Error className="mt-3">{error}</Error>}
      </div>
    );
  }
);
