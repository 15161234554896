import { useEffect, useMemo } from 'react';

import { useGetSalesExpertTeamByDistrict } from '@pbx/shared/api-client/services/agents';
import { useGetLocationStats } from '@pbx/shared/api-client/services/location';
import { useGetPriceByPostcode } from '@pbx/shared/api-client/services/products';
import { useGetPackagesByPostcode } from '@pbx/shared/api-client/services/products';
import { useAnalytics } from '@pbx/shared/analytics';
import { formatPostCodeToDistrict } from '@pbx/shared/utilities/transform-postcode-to-district';

export function useGetFeesAndStats(postCode: string | undefined = '') {
  const analytics = useAnalytics();

  // 1. Get postTown of the postcode if available and postcode exists
  const {
    refetch,
    data: postcodeData,
    isLoading: isLoadingPostcodeData,
    isFetched,
    isError: isPostcodeDataError,
  } = useGetPriceByPostcode(postCode, {
    enabled: false,
    retry: false,
  });

  // 2. Get packages by postcode (fires when postcode data is fetched)
  const {
    data: packagesCollection,
    isLoading: isLoadingPackagesData,
    isError: isPackagesDataError,
  } = useGetPackagesByPostcode(postCode || '', {
    enabled: isFetched && postcodeData?.errorCode === 0,
  });

  // 3. Get location stats of the postTown (fires when postcode data is fetched)
  const postTown = postcodeData?.postTown || '';
  const { data: locationStatsData, isLoading: isLoadingLocationData } =
    useGetLocationStats(postTown || '', {
      enabled: !!postTown,
    });

  // 4. Get sales experts of the districts in the location stats (fires when location stats data is fetched)
  const locationsDistricts = locationStatsData?.districts?.join(',') || '';
  const { data: salesExpertsData = [], isLoading: isLoadingExpertsData } =
    useGetSalesExpertTeamByDistrict(locationsDistricts, {
      enabled: !!locationsDistricts,
    });

  // Get total properties sold
  const totalPropertiesSold = useMemo(
    () =>
      locationStatsData?.statistics.find(
        (stat) => stat.subjectLine === 'all time property sales completed'
      )?.statisticValue || null,
    [locationStatsData]
  );

  // Get 3 random local experts with photo
  const localExperts = useMemo(
    () =>
      salesExpertsData
        .filter((expert) => !!expert?.photoUrl)
        .sort(() => Math.random() - 0.5) // Randomize the array
        .slice(0, 3), // Get first 3
    [salesExpertsData]
  );

  useEffect(() => {
    if (packagesCollection?.length) {
      const getBoost = packagesCollection.find(
        (product) =>
          String(product?.id) === process.env.NEXT_PUBLIC_PACKAGE_BOOST_ID
      );
      const getFullhouse = packagesCollection.find(
        (product) =>
          String(product?.id) === process.env.NEXT_PUBLIC_PACKAGE_FULL_HOUSE_ID
      );
      analytics.trackGAEvent('feeChecker', {
        journey: 'sell',
        response: 'valid postcode',
        availability: true,
        postcodeDistrict: formatPostCodeToDistrict(postCode),
        posttown: postTown?.toLowerCase(),
        dependentLocality: postcodeData?.dependentLocality?.toLowerCase() || '',
        boostpriceupfront: getBoost?.calculated_price,
        fullhousepriceupfront: getFullhouse?.calculated_price,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packagesCollection]);

  useEffect(() => {
    if (postcodeData?.errorCode === 404) {
      analytics.trackGAEvent('feeChecker', {
        journey: 'sell',
        response: 'valid postcode',
        availability: false,
        postcodeDistrict: formatPostCodeToDistrict(postCode),
        posttown: postTown.toLowerCase(),
        dependentlocality: postcodeData?.dependentLocality?.toLowerCase(),
        error_type: postcodeData.errorCode,
        error_message: postcodeData.errorMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postcodeData]);

  return {
    hasError: isPostcodeDataError || isPackagesDataError,
    postcodeNotFound: postcodeData?.errorCode === 404,
    getFeesAndStats: refetch,
    packagesCollection,
    totalPropertiesSold,
    localExperts,
    postTown,
    isLoadingFeesAndStats:
      isLoadingPostcodeData ||
      isLoadingLocationData ||
      isLoadingExpertsData ||
      isLoadingPackagesData,
  };
}
