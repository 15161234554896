import { Text } from '@pbx/shared/ui/common/text';
import { LinkButton } from '@pbx/shared/ui/common/link-button';
import { Icon } from '@pbx/shared/ui/common/icon';
import { Button } from '@pbx/shared/ui/common/button';
import { Link } from '@pbx/shared/ui/common/link';
import { useAnalytics } from '@pbx/shared/analytics';

export function ResultsAddOns() {
  const basePath = process.env.NEXT_PUBLIC_PB_BASE_PATH || '';
  const analytics = useAnalytics();

  function handleValuationButtonClick() {
    analytics.trackGAEvent('Button', {
      children: 'book a free valuation',
      href: `${basePath}/valuation-reason`,
    });
  }

  return (
    <div className="flex flex-1 flex-col gap-4">
      <Text variant="heading" size="smaller">
        Add-ons (optional)
      </Text>
      <Text variant="paragraph" size="medium" as="p">
        Customise your sale to only pay for the services you want. From hosted
        viewings to drone photography, there’s a range of optional extras.
      </Text>
      <LinkButton
        icon={<Icon name="arrow-forward" />}
        iconPosition="right"
        variant="tertiary"
        className="w-fit text-nowrap"
        label="See our selling packages"
        link={{ href: `${basePath}/services/our-packages` }}
        newTab={false}
      />
      <Button
        variant="primary"
        className="text-nowrap sm:w-full md:w-fit lg:w-fit"
        onClick={() => handleValuationButtonClick()}
      >
        <Link href={`${basePath}/valuation-reason`}>Book a free valuation</Link>
      </Button>
    </div>
  );
}
