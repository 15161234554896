import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { TPackage } from '../products.types';
import { GET_PACKAGE_BY_POSTCODE } from '../products.constants';

export async function getPackagesByPostcode(postcode: string) {
  return await fetchApi<TPackage[]>(
    `${process.env.NEXT_PUBLIC_PB_BASE_API_URL}/bigcommerce-sync-api/v1/products/getpackagesbypostcode/${postcode}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetPackagesByPostcode(
  postcode: string,
  config: TUseQueryOptions<TPackage[]> = {}
) {
  return useQuery({
    queryKey: [GET_PACKAGE_BY_POSTCODE, postcode],
    queryFn: () => getPackagesByPostcode(postcode),
    ...config,
  });
}
