'use client';

import { TEventData } from '../google-analytics-tracker.types';
import { TFieldEvent } from './form-tracking.types';
import { getFieldTrackingData } from './form-tracking.helpers';
import { dataLayerPush } from '../data-layer-push';

export function trackSubmit<T extends TEventData>(data: T) {
  dataLayerPush('form_submit', { ...data, event: 'form_submit' });
}

export function trackSubmitError(error: Error) {
  dataLayerPush('website_error', {
    event: 'website_error',
    error_type: 'form error',
    error_message: error.message,
  });
}

export function trackField(data: TFieldEvent) {
  dataLayerPush(data.event, data);
}

export function trackInputChange(
  element: HTMLFormElement,
  valueMap?: Record<string, string>
) {
  const trackingData = getFieldTrackingData(element, valueMap);

  if (trackingData) trackField(trackingData);
}
