export function convertCssStringToObject(
  cssString?: string
): React.CSSProperties {
  if (!cssString) {
    return {};
  }

  const removeAllEmptySpaces = (str: string) => str.replace(/\s/g, '');
  const cssStringWithoutEmptySpaces = removeAllEmptySpaces(cssString);

  return cssStringWithoutEmptySpaces.split(';').reduce((acc, css) => {
    const [key, value] = css.split(':');
    return { ...acc, [key]: value };
  }, {});
}
