import { ECallbackAgentType } from '../mortgages.types';

export enum EFieldName {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'emailAddress',
  WHAT_DO_YOU_NEED = 'whatDoYouNeed',
  WHERE_ARE_YOU_AT = 'purchaseStep',
  ANNUAL_INCOME = 'income',
  PROPERTY_VALUE = 'propertyValue',
  DEPOSIT = 'deposit',
  UK_CITIZEN = 'ukCitizen',
  ANY_CCJS = 'adverseCredit',
  EXTRA_SUPPORT = 'vulnerableCustomer',
  CALL_DATE = 'callDate',
  CALL_ISO_DATE = 'callIsoDate',
  CALL_TIME = 'callTime',
  WHEN_DO_YOU_NEED_REMORTGAGE = 'whenDoYouNeedRemortgage',
  ACCEPT_TCS = 'acceptedTerms',
  PB_USER_ID = 'pbUserId',
  CALL_BACK_WHEN = 'callbackWhen',
  CALL_BACK_AGENT_TYPE = 'callbackAgentType',
  PURCHASE_INTENTION = 'purchaseIntention',
  REMORTGAGE_INTENTION = 'remortgageIntention',
}

export enum EWhatDoYouNeed {
  PURCHASE = 'purchase',
  RE_MORTGAGE = 're-mortgage',
}

export enum EWhereYouAt {
  JUST_LOOKING = 'JustStartingOut',
  CASUALLY_LOOKING = 'CasuallyLooking',
  ACTIVELY_VIEWING = 'ActivelyViewing',
  READY_TO_OFFER = 'ReadyToOffer',
  MADE_ON_OFFER = 'MadeOffer',
  OFFER_ACCEPTED = 'OfferAccepted',
}

export enum EWhenDoYouNeedRemortgage {
  LESS_THAN_3_MONTHS = 'Within3Months',
  THREE_TO_SIX_MONTHS = 'Within6Months',
  SIX_MONTHS_PLUS = 'MoreThan6Months',
}

export enum EYesNo {
  YES = 'true',
  NO = 'false',
}

export type TMutationRequestBody = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  pbUserId?: number;
  purchaseIntention: {
    purchaseStep: EWhereYouAt;
    income: number;
    propertyValue: number;
    deposit: number;
    ukCitizen: boolean;
    adverseCredit: boolean;
    vulnerableCustomer: boolean;
  };
  remortgageIntention: {
    remortgageNeededWithin: EWhenDoYouNeedRemortgage;
  };
  acceptedTerms?: boolean;
  callbackAgentType?: ECallbackAgentType;
  callbackWhen?: string;
};
