'use client';

import React from 'react';

import { LeadCaptureForm } from '@pbx/shared/ui/forms/lead-capture-form';
import { Text } from '@pbx/shared/ui/common/text';
import { FeatureList } from '@pbx/shared/ui/common/feature-list';

import { TLeadCaptureFormBlockProps } from './lead-capture-form-block.types';
import { SubmissionSuccess } from './submission-success';

export function LeadCaptureFormBlock({
  title,
  description,
  featureList,
  onFormSubmit,
  isLoading,
  isAppointmentsLoading,
  isSuccess,
  isError,
  callbackAppointments,
  fetchAppointments,
  initialValues,
}: TLeadCaptureFormBlockProps) {
  return (
    <div className="bg-background-tertiary sm:py-8 md:py-10 lg:py-16">
      <div className="container">
        <div className="flex gap-8 sm:flex-col md:flex-col lg:flex-row">
          <div className="sm:w-full md:w-full lg:w-1/2">
            <div className="sm:max-w-full md:max-w-full lg:max-w-[455px]">
              <Text
                variant="heading"
                as="h2"
                size="large"
                className="text-foreground-primary mb-6"
              >
                {title}
              </Text>
              <Text
                variant="paragraph"
                as="p"
                size="large"
                className="text-foreground-secondary mb-14"
              >
                {description}
              </Text>
              {featureList && (
                <FeatureList {...featureList} listStyle="positive" />
              )}
            </div>
          </div>
          <div className="bg-background-primary sm:w-full sm:p-6 md:w-full md:p-6 lg:w-3/5">
            {isSuccess ? (
              <SubmissionSuccess />
            ) : (
              <LeadCaptureForm
                onFormSubmit={onFormSubmit}
                isLoading={isLoading}
                isAppointmentsLoading={isAppointmentsLoading}
                isError={isError}
                callbackAppointments={callbackAppointments}
                fetchAppointments={fetchAppointments}
                initialValues={initialValues}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
