import { cva } from 'class-variance-authority';

export const variants = cva('', {
  variants: {
    variant: {
      default: 'shadow-middle',
      error:
        'data-[state=checked]:bg-background-error focus:border-border-error focus:ring-0 border-border-error border-2 shadow-middle',
      disabled: 'data-[state=checked]:bg-background-disabled',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
